<template>
  <v-data-table
    v-model="mySelectedIds"
    class="transparent text-no-wrap"
    dense
    :headers="headers"
    :items="contacts"
    :server-items-length="totalContacts"
    :options.sync="options"
    item-key="id"
    :loading="loadingTable"
    show-select
    :footer-props="{
      itemsPerPageOptions: recordsPerPage,
    }"
  >
    <template v-slot:[`item.dispo`]="{ item }">
      <v-chip
        label
        v-if="item.dnc || item.dispo == 'DNC'"
        :color="getColor('DNC')"
        text-color="white"
        close
        style="margin:5px;"
        @click:close="rechurnDNC(item)"
      >
        <span style="font-size: 14px; color: #fafafa; font-weight: 400">
          DNC
        </span>
      </v-chip>

      <v-chip
        label
        v-else
        :color="getColor(item.dispo)"
        dark
        style="margin:5px;"
      >
        <span
          v-if="item.dispo == 'CALLBACK'"
          style="font-size: 14px; font-weight: 400"
        >
          {{ item.dispo }} ({{ $moment(item.callbackOn).format("lll") }})
        </span>
        <span v-else style="font-size: 14px; font-weight: 400">
          {{ item.dispo }}
        </span>
      </v-chip>

      <v-chip
        label
        v-if="item.smsSent"
        color="yellow"
        text-color="white"
        style="margin:5px;"
      >
        <span style="font-size: 14px; color: #fafafa; font-weight: 400">
          SMS
        </span>
      </v-chip>

      <v-chip
        label
        v-if="item.emailSent"
        color="blue"
        text-color="white"
        style="margin:5px;"
      >
        <span style="font-size: 14px; color: #fafafa; font-weight: 400">
          Email
        </span>
      </v-chip>

      <v-chip
        label
        v-if="item.linkSent"
        color="green"
        text-color="white"
        style="margin:5px;"
      >
        <span style="font-size: 14px; color: #fafafa; font-weight: 400">
          Link
        </span>
      </v-chip>

      <v-chip
        label
        v-if="item.appSent"
        color="orange"
        text-color="white"
        style="margin:5px;"
      >
        <span style="font-size: 14px; color: #fafafa; font-weight: 400">
          App
        </span>
      </v-chip>
    </template>
    <template v-slot:[`item.leadScore`]="{ item }">
      <v-chip v-if="item.leadScore >= 0">
        <span class="happiness mr-2"> {{ getHappiness(item.leadScore) }}</span>
        <span class="score"> {{ item.leadScore }} </span>
      </v-chip>
    </template>
    <template v-slot:[`item.phone`]="{ item }">
      <a
        text
        class="dotted-gradient secondary--text"
        @click="openDetailsDialog(item)"
        >{{ item.phone }}</a
      >
    </template>

    <template v-slot:[`item.name`]="{ item }">
      <a
        text
        class="dotted-gradient secondary--text"
        @click="openDetailsDialog(item)"
        >{{ item.name }}</a
      >
    </template>

    <template v-slot:[`item.createdOn`]="{ item }">
      {{ $moment(item.createdOn).format("Do MMMM YYYY, h:mm A") }}
    </template>

    <template v-slot:[`item.dealValue`]="{ item }">
      {{ currency ? currency : "₹" }}
      {{ item.dealValue != undefined ? item.dealValue : 0 }}
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");

export default {
  computed: {
    ...mapGetters(["dispoList", "ENDPOINT"]),
    headers: {
      get() {
        var head = this.$store.state.headers;
        var headvalue = _.uniq(head.map((h) => h.value));
        var filterHead = [];
        for (var h of headvalue) {
          var f = head.find((fh) => fh.value == h);
          filterHead.push(f);
        }
        return filterHead;
      },
      set(v) {
        this.$store.state.headers = v;
      },
    },
    currency() {
      return this.$store.getters.companyDetails.currency;
    },
  },
  props: {
    contacts: {
      type: Array,
    },
    totalContacts: {
      type: Number,
    },
    loadingTable: {
      type: Boolean,
    },
    selectedIds: {
      type: Array,
    },
  },
  data() {
    return {
      recordsPerPage: [5, 20, 50, 100, 250, 500],
      page: 1,
      pageCount: 0,
      options: {},
      mySelectedIds: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.$emit("update", this.options);
        var paginate = {};
        paginate[
          this.$router.currentRoute.name
            .toString()
            .trim()
            .replace(/ /g, "")
            .toLowerCase()
        ] = this.options;
        this.$store.commit("setPagination", paginate);
      },
      deep: true,
    },
    mySelectedIds() {
      this.$emit("selectedIdUpdate", this.mySelectedIds);
    },
    selectedIds(newV) {
      this.mySelectedIds = newV;
    },
  },
  created() {
    var paginate = this.$store.getters.paginate;
    var routeName = this.$router.currentRoute.name
      .toString()
      .trim()
      .replace(/ /g, "")
      .toLowerCase();

    this.options.itemsPerPage = paginate[routeName].itemsPerPage || 5;
    //this.options.sortBy = paginate[routeName].sortBy || "";
    //this.options.sortDesc = paginate[routeName].sortDesc || "";

    // this.$emit("update", this.options);-------------------------Extra call v4

    this.mySelectedIds = this.selectedIds;
  },
  methods: {
    // Rechurn DNC Contacts in list
    rechurnDNC(item) {
      this.loadingTable = true;
      var patchObj = {
        dispo: "",
        dnc: false,
        assignee: null,
        called: false,
        modifiedOn: new Date(),
      };
      this.$swal({
        title: "Are you sure?",
        text: "Remove this Contact from DNC?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1bca3f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Remove it!",
      })
        .then((willDelete) => {
          if (willDelete.value) {
            this.$http
              .patch(`${this.ENDPOINT}/contacts/${item.id}`, patchObj)
              .then((response) => {
                this.loadingTable = false;
                this.$swal({ type: "success", text: "Successful" });
                this.$emit("update", this.options);
              })
              .catch((err) => {
                this.loadingTable = false;
                console.log("err", err);

                if (err.body.error.message.includes("is not unique")) {
                  this.$swal({
                    type: "error",
                    text: "Something Went Wrong Rechuning Contacts",
                  });
                } else {
                  this.$swal({ type: "error", text: err.body.error.message });
                }
              });
          } else {
            this.loadingTable = false;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
          console.log(err);
        });
    },
    openDetailsDialog(item) {
      this.$emit("openDetailsDialog", item);
    },
    getColor(dispo) {
      const self = this;
      try {
        if (dispo == "NEW") return "primary";
        var d = _.find(self.dispoList, (d) => {
          return d.title == dispo;
        });
        if (d == undefined) {
          return "#E5E5E5";
        }
        return d.hex;
      } catch (e) {
        console.log("error", e);
      }
    },
    getHappiness(internalValue) {
      let moods = [
        "😡",
        "😠",
        "😦",
        "😟",
        "🙁",
        "😐",
        "🙂",
        "😊",
        "😄",
        "😃",
        "😍",
      ];

      if (internalValue == 0) {
        return "🤬";
      }
      // this.$emit('filter', filterData);

      return moods[Math.floor(internalValue / 10)];
    },
  },
};
// field fieldType search customFieldDate min max selectedDispo
</script>

<style></style>
