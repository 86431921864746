<template>
  <div class="pa-0 ma-0">
    <!-- Simple Search -->
    <!-- <v-row class="pa-0 ma-0">
      <v-col cols="1" md="1"></v-col>
      <v-col cols="8" md="8" class="pa-0 ma-0">
        <v-text-field
          v-model="value1"
          label="Phone / Name"
          prepend-inner-icon="mdi-magnify"
          dense
          flat
          solo-inverted
        />
      </v-col>
      <v-col cols="1" md="1" class="pa-0 ma-0">
        <v-btn
          dark
          depressed
          block
          class="mx-1 primarygrad"
          @click="simpleSearch()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="2" md="2" class="pa-0 ma-0">
        <v-badge
          color="red"
          :value="isFilterApplied"
          overlap
          offset-x="-1"
          bodered
        >
          <v-btn dark depressed block class="mx-2" @click="open">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </v-badge>
      </v-col>
    </v-row> -->
    <div class="d-block">
      <div class="d-flex flex-wrap">
        <v-card
          v-for="(filter, i) in allFilters"
          :key="i"
          flat
          style="margin-top:-2px;"
        >
          <v-menu v-if="allFilters.length > 0 && i != 0">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                label
                solo-inverted
                dense
                class="ma-1"
                style="text-overflow:ellipsis;height:43px;"
                v-bind="attrs"
                v-on="on"
              >{{ selectedOperator }}
                <v-icon
                  right
                  small
                >mdi-arrow-down-drop-circle</v-icon>
              </v-chip>
            </template>
            <v-list>
              <v-list-item @click="changeOperator('and', true)">
                <v-list-item-title>and</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeOperator('or', true)">
                <v-list-item-title>or</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-chip
            label
            solo
            dense
            class="ma-1 short"
            style="height:40px;"
          >
            <span>{{ filter.field.name }} {{ filter.option }} {{ filter.value
              }}{{ filter.value2 ? ` ~ ${filter.value2}` : "" }}</span>
            <v-icon
              right
              class="ma-1"
              @click="removeFilter(i, true)"
            >mdi-close-circle</v-icon>
          </v-chip>
        </v-card>
      </div>
    </div>
    <div class="d-block text-center">
      <v-dialog
        v-model="listSearchDialog"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 primarygrad">
            Advanced Filter
            <v-spacer></v-spacer>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>
          <v-card-text style="padding-top: 28px; padding-bottom: 0px">
            <v-layout>
              <div
                v-if="adv"
                class="d-flex flex-wrap"
              >
                <v-select
                  v-model="field"
                  prepend-inner-icon="mdi-filter"
                  placeholder="Choose Field"
                  :items="categories"
                  item-text="name"
                  return-object
                  dense
                  flat
                  solo-inverted
                  class="mx-1"
                  @change="selectField"
                />
                <v-select
                  v-model="option"
                  label="Operator"
                  :items="selectedOptions"
                  dense
                  flat
                  solo-inverted
                  class="mx-1"
                />
                <v-text-field
                  v-if="
                    field.type != 'date' &&
                      field.type != 'mcq' &&
                      field.type != 'checkbox' &&
                      option != '' &&
                      option != 'has any value' &&
                      option != 'is Blank' &&
                      typeof field.mcqOptions == 'undefined'
                  "
                  v-model="value"
                  label="Value"
                  dense
                  flat
                  solo-inverted
                  class="mx-1"
                  :type="field.type"
                />
                <v-text-field
                  v-if="
                    field.type != 'date' &&
                      option != '' &&
                      option == 'is between'
                  "
                  v-model="value2"
                  label="Value"
                  dense
                  flat
                  solo-inverted
                  class="mx-1"
                  :type="field.type"
                />
                <v-autocomplete
                  v-if="
                    field.type == 'mcq' &&
                      option != '' &&
                      field.value == 'assignee'
                  "
                  v-model="selectAssignee"
                  label="Assignee"
                  :items="allUsers"
                  item-text="name"
                  dense
                  flat
                  solo-inverted
                  return-object
                  class="mx-1"
                />
                <v-autocomplete
                  v-if="
                    (field.type == 'mcq' &&
                      option != '' &&
                      field.value != 'assignee') ||
                      (field.type == 'text' &&
                        typeof field.mcqOptions != 'undefined')
                  "
                  v-model="value"
                  label="Value"
                  :items="field.mcqOptions"
                  dense
                  flat
                  solo-inverted
                  class="mx-1"
                />
                <v-autocomplete
                  v-if="field.type == 'checkbox' && option != ''"
                  v-model="checkboxValue"
                  label="Value"
                  :items="field.checkboxOptions"
                  chips
                  small-chips
                  multiple
                  dense
                  flat
                  solo-inverted
                  class="mx-1"
                />

                <v-dialog
                  v-if="field.type == 'date' && option == 'is between'"
                  ref="dialog"
                  v-model="dateModal"
                  persistent
                  :return-value.sync="fieldDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateValues"
                      class="mx-1"
                      dense
                      single-line
                      label="Enter Date"
                      prepend-inner-icon="mdi-calendar"
                      solo-inverted
                      flat
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fieldDate"
                    range
                    scrollable
                    color="primarygrad"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        fieldDate = [];
                        dateModal = false;
                      "
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primarygrad white--text"
                      @click="dateAdd(fieldDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

                <v-dialog
                  v-if="
                    field.type == 'date' &&
                      (option == 'greater than' || option == 'less than')
                  "
                  ref="dialog2"
                  v-model="dateModal2"
                  :return-value.sync="fieldDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date2"
                      class="mx-1"
                      dense
                      single-line
                      label="Enter Date"
                      prepend-inner-icon="mdi-calendar"
                      solo-inverted
                      flat
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    scrollable
                    color="primarygrad"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dateModal2 = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primarygrad white--text"
                      @click="$refs.dialog2.save(date2)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

                <v-btn
                  text
                  @click="addFilter"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Add Filter
                </v-btn>

                <v-card
                  v-for="(filter, i) in allFilters"
                  :key="i"
                  flat
                  style="margin-top:-2px;"
                >
                  <v-menu v-if="allFilters.length > 0 && i != 0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        label
                        solo-inverted
                        dense
                        class="ma-1"
                        style="text-overflow:ellipsis;height:43px;"
                        v-bind="attrs"
                        v-on="on"
                      >{{ selectedOperator }}
                        <v-icon
                          right
                          small
                        >mdi-arrow-down-drop-circle</v-icon>
                      </v-chip>
                    </template>
                    <v-list>
                      <v-list-item @click="selectedOperator = 'and'">
                        <v-list-item-title>and</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="selectedOperator = 'or'">
                        <v-list-item-title>or</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-chip
                    label
                    solo
                    dense
                    class="ma-1 short"
                    style="height:40px;"
                  >
                    <span>{{ filter.field.name }} {{ filter.option }}
                      {{ filter.value
                      }}{{ filter.value2 ? ` ~ ${filter.value2}` : "" }}</span>
                    <v-icon
                      right
                      class="ma-1"
                      @click="removeFilter(i)"
                    >mdi-close-circle</v-icon>
                  </v-chip>
                </v-card>
              </div>
            </v-layout>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-flex
              xs12
              sm2
              md2
              style="padding: 2px 5px; margin-top: 1px"
            >
              <v-btn
                color="error"
                block
                text
                @click="resetFilter"
              >Reset</v-btn>
            </v-flex>
            <v-flex
              xs12
              sm2
              md2
              style="padding: 2px 5px; margin-top: 1px"
            >
              <v-btn
                depressed
                color="primarygrad"
                block
                @click="query"
              >Search</v-btn>
            </v-flex>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import searchFilter from "../js/searchFilter";
// import { Datetime } from "vue-datetime";
const _ = require("lodash");

export default {
  components: {
    // datetime: Datetime,
  },
  props: {
    allUsers: {
      type: Array
    }
  },
  data() {
    return {
      listSearchDialog: false,
      setfieldsList: [
        {
          name: "Phone",
          value: "phone",
          fieldType: "normalField",
          type: "text"
        },
        {
          name: "Secondary Phone",
          value: "secondPhone",
          fieldType: "normalField",
          type: "text"
        },
        { name: "Name", value: "name", fieldType: "normalField", type: "text" },
        {
          name: "Company Name",
          value: "companyName",
          fieldType: "normalField",
          type: "text"
        },
        {
          name: "Leadscore",
          value: "leadScore",
          fieldType: "normalField",
          type: "number"
        },
        {
          name: "Assignee",
          value: "assignee",
          fieldType: "normalField",
          type: "mcq"
        },
        {
          name: "Email",
          value: "email",
          fieldType: "normalField",
          type: "text"
        },
        {
          name: "Extra",
          value: "extra",
          fieldType: "normalField",
          type: "text"
        },
        {
          name: "Remarks",
          value: "remarks",
          fieldType: "normalField",
          type: "text"
        },
        { name: "Note", value: "note", fieldType: "normalField", type: "text" },
        {
          name: "Created On",
          value: "createdOn",
          fieldType: "normalFieldDate",
          type: "date"
        }
        // {
        //   name: "Products",
        //   value: "products",
        //   fieldType: "normalField",
        //   type: "product"
        // }
      ],
      searchfieldsList: [
        {
          name: "Phone",
          value: "phone",
          fieldType: "normalField",
          type: "text"
        },
        {
          name: "Secondary Phone",
          value: "secondPhone",
          fieldType: "normalField",
          type: "text"
        },
        { name: "Name", value: "name", fieldType: "normalField", type: "text" },
        {
          name: "Company Name",
          value: "companyName",
          fieldType: "normalField",
          type: "text"
        },
        {
          name: "Email",
          value: "email",
          fieldType: "normalField",
          type: "text"
        },
        {
          name: "Extra",
          value: "extra",
          fieldType: "normalField",
          type: "text"
        },
        {
          name: "Remarks",
          value: "remarks",
          fieldType: "normalField",
          type: "text"
        },
        { name: "Note", value: "note", fieldType: "normalField", type: "text" }
      ],
      allOptions: {
        number: ["greater than", "less than", "is between"],
        text: ["contains", "is", "is not", "is Blank"],
        date: ["is between", "greater than", "less than"],
        mcq: ["is", "is not"],
        checkbox: ["contains"]
        // product: ["is"],
      },
      sendOptions: {
        number: [
          { text: "greater than", value: "gte" },
          { text: "less than", value: "lte" },
          { text: "is between", value: "between" }
        ],

        text: [
          { text: "contains", value: "contains" },
          { text: "is", value: "eq" },
          { text: "is not", value: "ne" },
          { text: "is Blank", value: "blank" }
          // { text: "has any value", value: "exists" }
        ],
        date: [
          { text: "greater than", value: "gte" },
          { text: "less than", value: "lte" },
          { text: "is between", value: "between" }
        ],
        mcq: [
          { text: "is", value: "eq" },
          { text: "is not", value: "ne" }
        ],
        checkbox: [{ text: "contains", value: "contains" }],
        product: [{ text: "is", value: "eq" }]
      },
      adv: true,
      selectedOptions: [],
      operators: ["and", "or"],
      allFilters: [],
      selectedOperator: "or",
      displayField: false,
      field: { name: null, type: "text" },
      option: "",
      value: "",
      value1: "",
      value2: "",
      selectAssignee: "",
      valueAdded: false,
      dateModal: false,
      fieldDate: [],
      predicates: [],
      matchType: "",
      checkboxValue: [],
      loading: false,
      search: "",
      selected: [],
      menudisp: false,
      dateModal2: false,
      date2: ""
    };
  },
  watch: {
    selected() {
      this.search = "";
    },
    selectedOperator() {
      this.matchType = this.selectedOperator;
      if (this.predicates.length > 1) {
        this.matchType = this.selectedOperator;
      } else {
        this.matchType = "";
      }
      this.$store.commit("setMatchType", this.matchType);
    }
  },
  mounted() {
    this.predicates = this.$store.getters.predicates;
    this.allFilters = this.$store.getters.filters;
    this.matchType = this.$store.getters.matchType;
    this.getAllFields();
  },
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "user",
      "dispoList",
      "fieldList",
      "teams",
      "selectedTeam",
      "headers"
    ]),
    dateValues() {
      return this.fieldDate.join(" ~ ");
    },
    filterOptionsDispo() {
      return [
        ..._.map(this.dispoList, d => {
          return {
            type: "dispo",
            name: d.title,
            id: d.title
          };
        })
      ];
    },
    isFilterApplied() {
      return this.predicates.length > 0;
    },
    customFieldDateRangeText() {
      return this.customFieldDate.join(" ~ ");
    },
    categories() {
      const search = this.search.toLowerCase();
      if (!search) return this.setfieldsList;
      return this.setfieldsList.filter(item => {
        const text = item.name.toLowerCase();
        return text.indexOf(search) > -1;
      });
    }
  },
  methods: {
    open() {
      const self = this;
      self.fieldReset();
      self.listSearchDialog = true;
    },
    fieldReset() {
      const self = this;
      self.search = "";
      self.field = {
        name: null,
        value: null,
        fieldType: "normalField",
        type: "text"
      };
      self.option = null;
      self.value = null;
      self.value2 = null;
      self.checkboxValue = [];
      self.selectAssignee = null;
    },
    setFieldType() {
      var selectedField = _.find(this.setfieldsList, f => {
        return this.field == f.value;
      });

      this.fieldType = selectedField.fieldType;
    },
    async query() {
      if (this.predicates.length == 0) {
        await this.addFilter();
      }
      this.continueSearch();
    },
    continueSearch() {
      var filter = false;
      if (this.predicates.length > 0) filter = true;
      else {
        return;
        // return this.$swal({
        //   type: "error",
        //   text: "Please add at least one filter",
        // });
      }

      this.$store.commit("setPredicates", this.predicates);
      this.$store.commit("setFilters", this.allFilters);

      this.$emit("getFilters", this.predicates, this.matchType);
      this.listSearchDialog = false;
    },
    close() {
      this.fieldReset();
      this.$store.commit("setPredicates", this.predicates);
      this.$store.commit("setFilters", this.allFilters);

      this.$emit("getFilters", this.predicates, this.matchType);
      this.listSearchDialog = false;
    },
    filterName(filter) {},
    cancelFilter() {
      this.field = { name: null, type: "text" };
      this.option = "";
      this.value = "";
      this.value2 = "";
      this.selectAssignee = "";
      this.displayField = false;
    },
    async addFilter() {
      var sendValue = "";
      //this.menudisp = false;
      if (this.field.name == null || this.field.value == null) {
        return this.$swal({ type: "error", text: "Please select a field" });
      } else {
        if (this.field.type == "number") {
          if (this.value == "" || this.value == null) {
            return this.$swal({ type: "error", text: "Please select a value" });
          }
          if (this.option == "is between") {
            if (
              this.value == "" ||
              this.value == null ||
              this.value2 == "" ||
              this.value2 == null
            ) {
              return this.$swal({
                type: "error",
                text: "Please select both values"
              });
            }
          }

          if (this.value != "" && this.value2 != "") {
            sendValue = [Number(this.value), Number(this.value2)];
          } else {
            sendValue = [Number(this.value)];
          }
        } else if (this.field.type == "date") {
          if (this.option == "is between") {
            this.value = this.$moment(this.fieldDate[0]).format("DD-MM-YY");
            this.value2 = this.$moment(this.fieldDate[1]).format("DD-MM-YY");
          } else {
            this.value = this.$moment(this.date2).format("DD-MM-YY");
          }
          if (this.value == "" || this.value == null) {
            return this.$swal({ type: "error", text: "Please select a value" });
          }
          sendValue = _.map(this.fieldDate, dt => {
            return this.$moment(dt, "YYYY-MM-DD").format();
          });
        } else if (this.field.type == "checkbox") {
          if (this.checkboxValue.length == 0) {
            return this.$swal({ type: "error", text: "Please select a value" });
          }
          this.value = this.checkboxValue.join(" , ");
        } else if (
          this.field.type == "mcq" &&
          this.field.fieldType == "normalField"
        ) {
          sendValue = this.selectAssignee.id;
          this.value = this.selectAssignee.name;
          if (this.value == "" || this.value == null) {
            return this.$swal({ type: "error", text: "Please select a value" });
          }
        } else {
          if (
            (this.value == "" || this.value == null) &&
            this.option != "is Blank"
          ) {
            return this.$swal({ type: "error", text: "Please select a value" });
          }
          sendValue = this.value;
        }
      }

      // Trim Check
      if (typeof sendValue == "string") {
        sendValue = sendValue.trim();
      }

      this.allFilters.push({
        field: this.field,
        option: this.option,
        value: this.value,
        value2: this.value2
      });

      var payload = JSON.parse(JSON.stringify(this.field));
      var fieldValue = "";
      if (payload.type == "checkbox") fieldValue = this.checkboxValue;
      else if (payload.type == "mcq" && payload.fieldType == "normalField")
        fieldValue = this.selectAssignee.id;
      else fieldValue = this.value;

      var currentFilter = await searchFilter.getFilter(
        payload,
        fieldValue,
        _.filter(this.sendOptions[payload.type], f => {
          return f.text == this.option;
        })[0],
        this.value2
      );

      this.predicates.push(currentFilter);

      this.field = {
        name: null,
        type: "text"
      };
      this.option = "";
      this.value = "";
      this.value2 = "";
      this.fieldDate = [];
      this.date2 = "";
      this.checkboxValue = [];
      this.displayField = false;
    },
    async simpleSearch() {
      this.allFilters = [];
      this.predicates = [];
      this.$store.commit("setPredicates", this.predicates);
      this.$store.commit("setFilters", this.allFilters);

      var field1 = {
        name: "Phone",
        value: "phone",
        fieldType: "normalField",
        type: "text"
      };
      var field2 = {
        name: "Name",
        value: "name",
        fieldType: "normalField",
        type: "text"
      };

      var option = { text: "contains", value: "contains" };
      var pred1 = await searchFilter.getFilter(
        field1,
        this.value1,
        option,
        null
      );

      var pred2 = await searchFilter.getFilter(
        field2,
        this.value1,
        option,
        null
      );

      var predicates = [pred1, pred2];
      this.$emit("getFilters", predicates, "or");
    },
    resetFilter() {
      this.allFilters = [];
      this.predicates = [];

      this.$store.commit("setPredicates", this.predicates);
      this.$store.commit("setFilters", this.allFilters);
      this.$emit("getFilters", this.predicates, this.matchType);
      this.listSearchDialog = false;
    },

    removeFilter(i, update) {
      this.allFilters.splice(i, 1);
      this.predicates.splice(i, 1);

      this.$store.commit("setPredicates", this.predicates);
      this.$store.commit("setFilters", this.allFilters);

      if (update) {
        this.$emit("getFilters", this.predicates, this.matchType);
      }
    },

    selectField() {
      // this.field = field;
      this.selectedOptions = this.allOptions[this.field.type];
      if (
        this.field.type == "text" &&
        typeof this.field.mcqOptions != "undefined"
      ) {
        this.selectedOptions = this.allOptions["mcq"];
      }
      this.option = this.selectedOptions[0];
    },
    getAllFields() {
      // Set Custom Field in
      if (this.fieldList.length >= 1) {
        // for(var idx in this.fieldList.length){
        //   var field = this.fieldList[idx];
        //   field.fieldType = 'customField';
        //   this.setfieldsList.push(field);
        // }
        this.fieldList.forEach(field => {
          field.fieldType = "customField";
          field.value = field.name;
          this.setfieldsList.push(field);
        });
      }

      // Autoselect Search By Field value
      // if (this.setfieldsList.length > 0 && this.field == "") {
      //   this.field = this.setfieldsList[0].value;
      // }
    },
    dateAdd(fieldDate) {
      this.$refs.dialog.save(fieldDate);
    },
    changeOperator(operator, update) {
      this.selectedOperator = operator;
      if (update) {
        this.$emit("getFilters", this.predicates, this.matchType, true);
      }
    }
  }
};
// field fieldType search customFieldDate min max selectedDispo
</script>

<style scoped>
.short span {
  white-space: nowrap;
  overflow: hidden;
  max-width: 200px;
  text-overflow: ellipsis;
}
</style>
``
