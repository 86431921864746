<template>
  <div>
    <v-dialog v-model="setAssigneeDialog" persistent width="450">
      <v-card>
        <v-card-title class="primarygrad" >Set Assignee</v-card-title>
        <v-card-text class="py-5">
          <v-select
            v-model="selectedAssignee"
            label="Select Member"
            placeholder="Select Member"
            autofocus 
            filled 
            outlined
            dense 
            hide-details
            :items="allUsers"
            item-value="id"
            item-text="name"/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="setAssigneeDialog = false">Cancel</v-btn>
          <v-btn color="primarygrad" @click="saveAssignee"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-dialog v-model="loader" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text style="padding: 18px 25px">
            <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
              Please Wait...
            </h3>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    allUsers: {
      type: Array,
    },
    selectedIds: {
      type: Array,
    },
  },
  data() {
    return {
      selectedAssignee: "",
      setAssigneeDialog: false,
      loader: false,
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT"]),
  },
  methods: {
    open() {
      this.setAssigneeDialog = true;
    },
    saveAssignee() {
      this.loader = true;
      var ids = _.map(this.selectedIds, "id");

      if (ids.length < 1) {
        this.$swal({ type: "error", text: "No contacts is Selected" });
      }

      if (this.selectedAssignee == null || this.selectedAssignee == "") {
        this.$swal({ type: "error", text: "Select an assignee" });
      }

      this.$http
        .post(`${this.ENDPOINT}/contacts/setassignee`, {
          ids: ids,
          targetAssignee: this.selectedAssignee,
        })
        .then((response) => {
          this.loader = false;
          this.$swal({ type: "success", text: "Successful" });
          this.refresh();
          this.setAssigneeDialog = false;
        })
        .catch((err) => {
          // this.loader = false;
          console.log("Error", err);
          if (err.body.error.message.includes("is not unique")) {
            this.$swal({
              type: "error",
              text: "Something Went Wrong While Assigning contacts",
            });
          } else {
            this.$swal({ type: "error", text: err.body.error.message });
          }
        });
    },
    refresh() {
      this.$emit("refreshData");
    },
  },
};
</script>
