<template>
  <v-dialog v-model="productDialog" width="500">
    <v-card>
      <v-card-title>
        <span class="headline">Select Product</span>
      </v-card-title>

      <v-row v-if="productList.length > 0" style="margin: 0px">
        <v-flex
          v-for="product in productList.filter((d) => {
            return !d.system;
          })"
          :key="product._id"
          xs12
          sm6
          md6
          style="padding: 5px"
        >
          <v-card flat class="rounded inverted">
            <v-card-title>
              <v-icon :color="product.hex">fiber_manual_record</v-icon>
              <h6 class="font-weight-medium secondary--text pl-3">
                {{ product.title }}
              </h6>
              <v-spacer></v-spacer>

              <v-checkbox v-model="productIds" :value="product.id" dense />

              <!-- <v-menu
                origin="center center"
                :close-on-content-click="false"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-btn small text fab v-on="on">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="productEdit(product)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="del(product)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu> -->
            </v-card-title>
          </v-card>
        </v-flex>
      </v-row>
      <v-card-actions class="d-flex pa-3">
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">Cancel</v-btn>
        <v-btn depressed dark class="primarygrad" @click="addProductInContact"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "user",
      "dispoList",
      "fieldList",
      "teams",
      "selectedTeam",
      "selectedList",
    ]),
    companyDetails() {
      return this.$store.getters.companyDetails;
    },
  },
  data() {
    return {
      productDialog: false,
      loader: false,
      productList: [],
      productIds: [],
      contactSelected: "",
    };
  },
  async created() {
    var self = this;
    this.getProducts();
  },
  methods: {
    openDialog(item, products) {
      console.log("item", item);
      this.productIds = [];
      this.productDialog = true;
      if (products && products.length > 0) {
        this.productIds = _.map(products, "id");
      }

      this.contactSelected = item;
    },

    closeDialog() {
      this.productDialog = false;
    },

    addProductInContact() {
      console.log("Product Id", this.productIds);

      var postObj = {
        productsId: this.productIds,
        contactId: this.contactSelected.id,
      };

      this.$http
        .post(`${this.ENDPOINT}/products/set-product`, postObj)
        .then((response) => {
          console.log(response.body);

          this.closeDialog();
          this.$emit("updatedContact", this.contactSelected);
        });
    },
    async getProducts() {
      var self = this;
      var filter = {
        where: {
          companyId: this.$store.getters.user.companyId,
        },
      };

      try {
        var response = await this.$http.get(
          `${self.$store.state.ENDPOINT}/products?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        );
        self.productList = response.body;

        self.loader = false;
      } catch (err) {
        console.log("Error", err);

        self.loader = false;

        self.$swal({
          type: "error",
          text: "Something went wrong. Please try again",
        });
      }
    },
  },
};
</script>
<style>
.v-input__slot:before {
  border-color: rgb(0 0 0 / 2%) !important;
}
</style>
