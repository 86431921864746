<template>
  <v-dialog v-model="isDialogOpen" persistent width="400">
    <v-card>
      <v-card-title class="primarygrad" style="color: white;background-color: #00CF46;">{{ formTitle }}</v-card-title>
      <v-card-text class="py-5">
        <v-text-field v-model="listItem.name" autofocus filled outlined dense label="Name*" />
        <v-select v-if="
          formTitle == 'Edit' &&
          ($store.getters.user.role.toLowerCase() == 'manager' ||
            $store.getters.user.role.toLowerCase() == 'teammanager')
        " v-model="listItem.teamsId" label="Select Team" autofocus filled outlined dense hide-details :items="teams.filter((t) => {
            return t.id != 'allTeams';
          })
            " item-value="id" item-text="name" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined color="#00CF46" class="text-transform" @click="isOpen">Cancel</v-btn>
        <v-btn v-if="formTitle == 'New'" color="#00CF46" class="white--text mr-3 text-transform" depressed
          @click="addList">Create</v-btn>
        <v-btn v-else outlined color="#00CF46" class="text-transform" @click="updateList">Edit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script src="https://unpkg.com/vue-multiselect@2.1.0"></script>
<script>
import Multiselect from "vue-multiselect";
import { mapGetters, mapState } from "vuex";
import PieChart from "../js/pie.js";
import searchFilter from "../js/searchFilter.js";
import BarChart from "../js/bar.js";
import contactImport from "../components/contactImport.vue";
import contactImportV2 from "../components/contactImportV2.vue";
import assignMember from "../components/assignMember.vue";
import setAssignee from "../components/setAssignee.vue";
import callScript from "../components/callScript.vue";
import automationRules from "../components/automationRules.vue";
import listSearchFilter from "../components/listSearchFilter.vue";
import listAndContactTable from "../components/listAndContactTable.vue";

import DispoComponent from "../components/dispoComponent.vue";
import Search from "../components/search.vue";

const _ = require("lodash");

import fb from "../js/firebase";
import contactDisplayPopup from "../components/contactDisplayPopup";
import * as XLSX from "xlsx/xlsx.mjs";

// import EventBus from "../../../godial-app-enterprise/src/js/event-bus.js";
export default {
  components: {
    PieChart,
    BarChart,
    Multiselect,
    contactImport,
    contactImportV2,
    contactDisplayPopup,
    assignMember,
    callScript,
    automationRules,
    setAssignee,
    listSearchFilter,
    listAndContactTable,
    DispoComponent,
    Search,
  },
  data() {
    return {
      bulkInteraktEvent: false,
      automationDialog: false,
      agentCard: false,
      selectedProduct: [],
      show: true,
      loadingTable: false,
      leadScore: 60,
      assignmentAlert: false,
      selectedDispo: [],
      //  showDispoFilter : false,
      selectedFilterOptions: [],
      rulesNum: [
        (v) =>
          /^[0-9]+$/.test(v) ||
          "Must Not Contain Alphabets, Special Characters Or Space",
      ],
      labelShown: true,
      showDetailsDialog: false,
      movetoListSelection: "",
      changeListDialog: false,
      addListDialog: false,
      listItem: {
        name: "",
        teamsId: "",
        desc: "",
      },
      min: 0,
      max: 100,
      fileInput: false,
      step: 1,
      pasteCSV: false,
      csvPastedData: "",
      editedIndex: -1,
      dialogListEdit: false,
      listEditedItem: {
        name: "",
      },
      addCountryCode: false,
      countryCode: "",
      resultDialog: "",
      // required
      selected: [],
      called: 0,
      uncalled: 0,
      totalCalled: 0,
      datacollection: [],
      datacollection2: [],
      loadChart: false,
      rechurnDialog: false,
      // selectUsersForAssignment: [],
      assignedUsers: [],
      importedItem: {
        name: "",
        phone: "",
        secondPhone: "",
        email: "",
        companyName: "",
        extra: "",
        remarks: "",
        list: "",
        customFields: {},
      },
      preloader: false,
      response: {},
      headerList: [],
      importedData: [],
      menu_btn: false,
      importDialog: false,
      dispoDialog: false,
      bulkDispo: {},
      selectedIds: [],
      loader: false,
      search: "",
      selectedList: "",
      customFields: [],
      dialog: false,
      selectListFromCSV: false,
      selectUsersForAssignment: [],
      lists: [],
      contacts: [],
      options: {
        classes: "table",
        tableName: "contacts",
        editMode: "modal",
        url: this.ENDPOINT,
        customActionTmp: "",
        buttonsFloat: "left",
      },
      // pagination structure

      totalRecords: 0,

      // allUsers: [],
      field: "",
      fieldsList: [],
      setfieldsList: [
        { name: "Phone", value: "phone", fieldType: "normalField" },
        {
          name: "Secondary Phone",
          value: "secondPhone",
          fieldType: "normalField",
        },
        { name: "Name", value: "name", fieldType: "normalField" },
        {
          name: "Company Name",
          value: "companyName",
          fieldType: "normalField",
        },
        { name: "Email", value: "email", fieldType: "normalField" },
        { name: "Extra", value: "extra", fieldType: "normalField" },
        { name: "Remarks", value: "remarks", fieldType: "normalField" },
        { name: "Note", value: "note", fieldType: "normalField" },
        {
          name: "Created On",
          value: "createdOn",
          fieldType: "normalFieldDate",
        },
        {
          name: "Lead Score",
          value: "leadScore",
          fieldType: "number",
        },
        // {
        //   name: "Product",
        //   value: "productList",
        //   fieldType: "normalField",
        // },
      ],

      selectedHeaders: [],
      allHeaders: [
        {
          text: "Phone",
          align: "left",
          sortable: false,
          value: "phone",
        },
        {
          value: "secondPhone",
          text: "Secondary Phone",
          align: "left",
          sortable: false,
        },
        {
          text: "Lead Score",
          align: "left",
          sortable: false,
          value: "leadScore",
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          value: "companyName",
          text: "Company Name",
          align: "left",
          sortable: false,
        },
        {
          text: "Email",
          align: "left",
          sortable: false,
          value: "email",
        },
        {
          text: "Disposition",
          align: "left",
          sortable: true,
          value: "dispo",
        },
        {
          text: "Extra",
          align: "left",
          sortable: false,
          value: "extra",
        },
        {
          text: "Remarks",
          align: "left",
          sortable: false,
          value: "remarks",
        },
        {
          text: "Address",
          align: "left",
          sortable: false,
          value: "address",
        },
        {
          text: "Note",
          align: "left",
          sortable: false,
          value: "note",
        },
        {
          text: "Total Duration",
          align: "left",
          sortable: false,
          value: "TotalDuration",
        },
        {
          text: "Created On",
          align: "left",
          sortable: true,
          value: "createdOn",
        },
        {
          text: "Assignee",
          align: "left",
          sortable: false,
          value: "assigneeName",
        },
        {
          text: "Deal Value",
          align: "right",
          sortable: false,
          value: "dealValue",
        },
        {
          value: "productList",
          text: "Products",
          align: "left",
          sortable: false,
        },
      ],
      createdOnDate: this.$moment().format(),
      changeListResult: [],
      callScriptDialog: false,
      isCustomField: false,
      isCustomDate: false,
      isCustomNum: false,
      customFieldDate: [],
      customDateModal: false,
      isSelectedAll: false,
      fieldType: "normalField",
      contactsFiltered: false,
      showResetFilter: false,
      predicates: [],
      matchType: "",
      filterAdded: false,
      isFilterApplied: false,
      forms: [],
      targetEventInterakt: "",
      allowedEvents: [],
      selectedContactProducts: [],
      interakt: {
        type: "interakt",
        config: {
          key: "",
          events: [],
        },
        events: [],
        companyId: "",
        enabled: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "user",
      "dispoList",
      "fieldList",
      "teams",
      "selectedTeam",
      "companyDetails",
    ]),

    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },

    headers: {
      get: function() {
        return this.$store.getters.headers;
      },
      set: function(newValue) {
        this.$store.commit("setHeaders", newValue);
      },
    },
    //get users for that list's teamsId
    storeSelectList() {
      return this.$store.getters.selectedList;
    },

    assignMembers() {
      return require("../assets/assignMembers.png");
    },
    noListContact() {
      return require("../assets/noListContact.png");
    },
    paginate() {
      return this.$store.getters.paginate;
    },
    filterOptionsDispo() {
      return [
        ..._.map(this.dispoList, (d) => {
          return {
            type: "dispo",
            name: d.title,
            id: d.title,
          };
        }),
      ];
    },
    // customFieldDateRangeText() {
    //   return this.customFieldDate.join(" ~ ");
    // },
    contactNotSelected() {
      return this.selectedIds.length < 1;
    },
    showIcon() {
      return this.selectedList != "allLists" && this.contactNotSelected;
    },
    showAssigneeGraphs() {
      return (
        this.selectedList != "allLists" &&
        this.datacollection2.labels &&
        this.datacollection2.labels.length > 0
      );
    },
  },
  props: ["isOpen", "isDialogOpen"],
  watch: {
    async selectedTeam() {
      try {
        this.selectedList = "";
        // this.getAllFields();

        this.clearImportedItem();
        this.getUserDetails();
        await this.fetchLists(); // fetch list
        this.refresh();
      } catch (e) {
        console.log(e);
      }
    },
    selectedList(newVal) {
      this.$store.commit("setSelectedList", newVal);
    },
  },
  async created() {
    const self = this;
    try {
      self.getUserDetails(); // accounts
      // self.getAllFields();

      //init
      await self.fetchLists(); // fetch list
      self.refresh();
      // query
    } catch (e) {
      console.log(e);
    }
  },

  methods: {
    updateSelectedIds(ids) {
      this.selectedIds = ids;
    },

    async refreshList() {
      await this.refresh();
      await this.fetchLists();
    },

    //open assign member
    openAssignMember() {
      this.$refs.assignMember.selectedAssignMember("Lists");
    },

    // close all module
    close() {
      this.selectUsersForAssignment = [];

      //loader
      this.loader = false;

      // dialogs
      this.dialog = false;
      this.importDialog = false;
      // this.assignMemberDialog = false;

      // function call
      this.refresh();
    },

    //Get Accounts
    getUserDetails() {
      this.allUsers = [];
      // Teams sections
      var teams = this.getTeamIds();

      this.$http
        .post(`${this.$store.state.ENDPOINT}/accounts/fetch`, {
          teams: teams,
        })
        .then((response) => {
          this.allUsers = response.body;
        })
        .catch((err) => {
          this.loader = false;
        });
    },

    /** recieve teams and lists array of ids */
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    getListIds() {
      var lists = [];
      if (this.selectedList == "allLists") {
        lists = _.map(this.lists, "id");
        // lists = []
      } else if (this.selectedList != "") {
        lists.push(this.selectedList);
      }

      lists = _.without(lists, "allLists");
      return lists;
    },
    // Fetch lists
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];

        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        if (this.lists.length < 1) {
          this.loader = false;
          return;
        }

        this.lists.push({
          id: "allLists",
          name: "All Lists",
        });

        // Here Check has any Store Saved SelectedList & Assign to Store Selected List
        if (this.storeSelectList != "") {
          let findList = _.find(this.lists, (l) => {
            return l.id == this.storeSelectList;
          });
          this.selectedList = this.lists.length > 0 ? this.lists[0].id : "";
          if (findList) {
            this.selectedList = findList.id;
          }
        }

        if (
          _.filter(this.lists, (d) => {
            return this.selectedList.toString() === d.id.toString();
          }).length < 1 &&
          this.storeSelectList == ""
        ) {
          this.selectedList = this.lists.length > 0 ? this.lists[0].id : "";
        }
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },

    async fetchCountV2() {
      this.loader = true;
      // Lists sections
      var lists = this.getListIds();

      var dispos = _.map(this.selectedDispo, (d) => {
        return d.id;
      });

      if (dispos.length == 0) {
        dispos = _.map(this.dispoList, (d) => {
          return d.title;
        });
      }

      // check if lists are available and if not throw error
      if (lists.length < 1) {
        this.loader = false;

        return;
      }

      var payload = await searchFilter.getFilterPayload(
        this.predicates,
        dispos,
        this.matchType,
        "asc"
      );

      this.$http
        .post(`${this.$store.state.ENDPOINT}/contacts/byFilterV4`, {
          queryCase: "count",
          predicates: payload.predicates,
          matchType: payload.matchType,
          lists: lists,
          dispos: dispos,
        })
        .then((res) => {
          this.loader = false;
          this.totalRecords = res.body.count;

          this.pageCount = Math.ceil(this.totalRecords / this.records);
          this.totalCalled = res.body.cCount;
          return;
        })
        .catch((err) => {
          this.loader = false;
        });
    },

    // refresh data
    async refresh() {
      try {
        //this.contacts = [];
        this.assignedUsers = [];
        this.selectUsersForAssignment = [];
        this.selectedIds = [];
        this.search = "";
        this.isSelectedAll = false;
        this.selectedProduct = [];

        if (this.selectedList != "allLists" && this.selectedList != "") {
          await this.getUserByListId(this.selectedList);
          this.getForms();
        }
      } catch (e) {
        this.loader = false;
      }
    },

    openAddList() {
      this.editedIndex = -1;
      this.listItem = {
        name: "",
        teamsId: "",
        desc: "",
      };
      this.addListDialog = this.isDialogOpen;
    },

    addContact() {
      var self = this;
      if (self.selectedList === "") {
        this.$swal({
          type: "warning",
          text: "There is no list in the CRM. Please insert a New List.",
        });
        this.loader = false;
      } else {
        this.$refs.contactDisplayPopup.open(false);
      }
    },

    listEdit() {
      var self = this;
      if (self.selectedList === "") {
        this.$swal({
          type: "warning",
          text: "There is no list in the CRM. Please insert a New List.",
        });
        this.loader = false;
      } else {
        var selectedListObj = null;
        for (var x in this.lists) {
          if (this.lists[x].id == this.selectedList) {
            selectedListObj = this.lists[x];
          }
        }
        this.editedIndex = this.lists.indexOf(selectedListObj);
        this.listItem = Object.assign(selectedListObj);

        this.addListDialog = true;
      }
    },

    async updateList() {
      const self = this;
      try {
        var listInfo = await self.$http.get(
          self.$store.state.ENDPOINT + "/lists/" + self.selectedList
        );

        if (self.listItem.teamsId != listInfo.body.teamsId) {
          if (self.assignedUsers.length > 0) {
            return self.$swal({
              type: "warning",
              text: "Remove the assignments before moving it to other team",
            });
          }
        }

        var listObj = {
          name: self.listItem.name,
          desc: self.listItem.desc,
          teamsId: self.listItem.teamsId,
        };

        await self.$http.patch(
          `${self.ENDPOINT}/lists/${self.selectedList}`,
          listObj
        );

        self.addListDialog = false;
        self.refresh();
        await self.fetchLists();
      } catch (e) {
        console.log(e);
        self.$swal({
          type: "warning",
          text: "Error occured. Please try again.",
        });
      }
    },
    isMobile() {
      if (window.innerWidth <= 760) {
        return true;
      } else {
        return false;
      }
    },

    //rechurn the contacts based on the selected ids
    rechurnBasedOnId() {
      var ids = _.map(this.selectedIds, "id");
      if (ids.length < 1) {
        this.loader = false;
        return;
      }
      this.$swal({
        title: "Are you sure?",
        text: "You want to Rechurn " + this.selectedIds.length + " contacts",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1bca3f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, rechurn it!",
      }).then((res) => {
        if (res.value) {
          this.loader = true;
          this.$http
            .post(`${this.ENDPOINT}/contacts/bulk/rechurn`, {
              ids: ids,
            })
            .then((response) => {
              this.refresh();
              this.selectedIds = [];
              this.isSelectedAll = false;
              this.loader = false;
            })
            .catch((e) => {
              this.loader = false;
            });
        }
      });
    },

    //get users by list id
    async getUserByListId(id) {
      this.preloader = true;
      this.assignmentAlert = false;
      this.assignedUsers = [];
      this.selectUsersForAssignment = [];
      if (typeof id == "undefined" || id == undefined || id == "") {
        this.preloader = false;

        return; //return if id doesnt exist
      }
      try {
        var response = await this.$http.post(
          `${this.ENDPOINT}/listsAccountThroughs/get-account-through-list`,
          {
            listId: id,
          }
        );
        this.preloader = false;
        this.assignedUsers = response.body;
        this.selectUsersForAssignment = response.body;
        if (this.totalRecords > 0 && this.assignedUsers.length < 1) {
          // open the alert dialog
          this.assignmentAlert = true;
          return;
        }
      } catch (e) {
        console.log("error", e);
        this.loader = false;
        this.preloader = false;
        this.$swal({ type: "error", text: "Error while getting accounts" });
      }
    },

    // add list
    addList() {
      const self = this;
      var teamsId = this.$store.getters.selectedTeam;

      if (this.listItem.name == "") {
        return this.$swal({
          type: "warning",
          text: "You need to write something!",
        });
      }

      if (teamsId == "allTeams") {
        return this.$swal({
          type: "warning",
          text: "Select a team from the side-panel before creating list.",
        });
      }

      var jsonObj = {
        name: this.listItem.name,
        teamsId: teamsId,
        desc: this.listItem.desc,
        companyId: this.user.companyId,
      };

      this.$http
        .post(this.ENDPOINT + "/lists", jsonObj)
        .then(async function(response) {
          this.isOpen();
          fb.log("list_add");
          self.$swal({
            type: "success",
            text: `${this.listItem.name} is added successfully`,
          });

          await self.fetchLists();
          self.refresh();
        })
        .catch((err) => {
          console.log("e2", err);
          this.$swal({
            type: "error",
            text: "Sorry, same list name is present",
          });
        });
    },

    // delete assigned member from the list
    deleteAssignment(user) {
      const self = this;
      // confirm deletion
      self.loader = true;
      self
        .$swal({
          type: "warning",
          title: `Are you sure?`,
          text: `${user.name} will be removed from assignment?`,
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            self.$http
              .post(
                `${self.ENDPOINT}/listsAccountThroughs/remove-list-account-through`,
                {
                  listsId: self.selectedList,
                  accountIds: [user.id],
                }
              )
              .then((response) => {
                self.loader = false;
                self.$swal({ type: "success", text: "Successful" });
                self.close();
              })
              .catch((err) => {
                self.loader = false;
                console.log("Error", err);
                self.$swal({ type: "error", text: "Something went wrong" });
              });
          } else {
            self.loader = false;
          }
        });
    },
    clearList() {
      const self = this;

      if (self.selectedList == "") {
        return self.$swal({ type: "warning", text: "List is not selected" });
      }
      self
        .$swal({
          type: "warning",
          title: `Are you sure?`,
          text: `This will delete all contacts of this list`,
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            self.$http
              .post(self.ENDPOINT + "/lists/removeContacts", {
                listId: self.selectedList,
              })
              .then(async (resp) => {
                self.refresh();
                await self.fetchLists();
                fb.log("list_contact_delete");
                self.$swal({ type: "success", text: resp.body });
              })
              .catch((e) => {
                console.log("Clear List Error", e);
                self.$swal({ type: "error", text: "Something went wrong" });
              });
          }
        });
    },
    // remove list with all the contacts assigned to it
    removeList() {
      if (this.selectedList == "") {
        return this.$swal({ type: "warning", text: "List is not selected" });
      }

      console.log("this.selectedList", this.selectedList);

      const self = this;
      self
        .$swal({
          type: "warning",
          title: `Are you sure?`,
          text: `This will delete the list and all it's contacts`,
          showCancelButton: true,
          confirmButtonColor: "#1bca3f",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.value) {
            self.$http
              .delete(this.ENDPOINT + "/lists/" + self.selectedList)
              .then(async function() {
                self.selectedList = "";
                // await self.fetchLists(); // fetch list

                await self.fetchLists();
                self.refresh();
                fb.log("list_delete");
                self.$swal({ type: "success", text: "Successful" });
              })
              .catch((err) => {
                self.$swal({ type: "error", text: err.body.error.message });
              });
          }
        });
    },

    scroll() {
      window.scrollTo(0, document.body.scrollHeight);
    },

    resetAll() {
      this.getFilters([], "or");
    },
  },
};
</script>
