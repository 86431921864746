<template>
  <form id="slider" @submit.prevent>
    <!-- This label and input are the "real" elements. -->
    <label for="range" :style="{ color: getHappinessColor }"
      >Lead Score : {{ internalValue }}</label
    >
    <input
      v-model.number="internalValue"
      id="range"
      type="range"
      min="0"
      max="100"
      @input="change"
    />

    <!-- This div and label is what makes the visual effect, but is hidden to assistive technologies -->
    <div class="slider outer">
      <label
        for="range"
        :style="{
          width: `${internalValue}%`,
          borderRadius: greaterThanFifty,
          background: getSliderBackground,
        }"
        class="slider inner"
        aria-hidden="true"
      >
      </label>
      <span class="emoji" :style="{ transform: `translateX(${getPlacement})` }">
        {{ getHappiness }}
      </span>
    </div>
  </form>
</template>
<script>
export default {
  props: ["val"],
  data() {
    return {
      internalValue: 60,
    };
  },
  watch: {
    val(oldV) {
      this.internalValue = typeof oldV == "undefined" ? 60 : oldV;
      this.internalValue = isNaN(Number(this.internalValue))
        ? 60
        : Number(this.internalValue);
    },
  },
  created() {
    this.internalValue =
      typeof this.val == "undefined" || typeof this.val == "string"
        ? 60
        : this.val;
    this.internalValue = isNaN(Number(this.internalValue))
      ? 60
      : Number(this.internalValue);
  },
  computed: {
    // This is not ideal just because it needs knowledge of how wide the track is.
    // Ideally I'd like to do this more dynamically so it works with any track length,
    // but for the sake of smooth animations I kept it like this.
    getPlacement() {
      // Change Value to 13.5 (Width*)
      return this.internalValue * 7 + `%`;
    },

    // This is kinda hacky, but if the background is rounded at too low a value, you can see it peeking out from behind the emoji.
    greaterThanFifty() {
      return this.internalValue > 50 ? `var(--roundness)` : `0`;
    },

    // Makes the color smoothly move from red to orange to yellow
    getHappinessColor() {
      return `rgba(255, ${106 + (103 / 100) * this.internalValue}, ${Math.floor(
        (this.internalValue * -1) / 7.692
      ) + 13}`;
    },

    // Adjusts the amount of yellow in the filled slider
    getSliderBackground() {
      return `linear-gradient(to right, var(--orange), ${this.internalValue *
        -1 +
        125}%, var(--green))`;
    },

    // Changes which emoji is displayed
    getHappiness() {
      let moods = [
        "😡",
        "😠",
        "😦",
        "😟",
        "🙁",
        "😐",
        "🙂",
        "😊",
        "😄",
        "😃",
        "😍",
      ];

      if (this.internalValue === 0) {
        return "🤬";
      }
      // this.$emit('filter', filterData);

      return moods[Math.floor(this.internalValue / 10)];
    },
  },
  methods: {
    change(e) {
      this.internalValue = e.target.value;
      this.$emit("update:val", e.target.value);
    },
  },
};
</script>
<style>
:root {
  --green: rgb(76, 217, 100);
  --orange: #ebde2d;
  --darkGray: #53565a;
  --midGray: #888b8d;
  --white: #fff;
  --roundness: 1.25em;
}
/* 
*,
*:after,
*:before {
  box-sizing: border-box;
  color: var(--darkGray);
} */

#slider {
  padding: 2rem 2rem 4remq;
  display: grid;
  justify-content: stretch;
  width: 100%;
  max-width: 37.5em;
  margin: auto;
}
#slider input {
  grid-row: 2/3;
  grid-column: 1/2;
  width: 100%;
  position: relative;
  z-index: 1;
  opacity: 0;
  height: calc(var(--roundness) * 2);
  cursor: pointer;
}
#slider input:focus + .outer {
  box-shadow: 0 0 0 0.1em var(--white), 0 0 0 0.2em var(--orange);
}
#slider input:focus + .outer .emoji {
  /* Alternate focus style on the emoji button */
}
#slider > label {
  width: 100%;
  margin: auto;
  font-size: 1.5em;
  padding: 0 0 0.5em;
}
#slider .outer {
  grid-row: 2/3;
  grid-column: 1/2;
  width: 100%;
  border-radius: var(--roundness);
  margin: auto;
  height: calc(var(--roundness) - 0em);
  background: var(--midGray);
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
}
#slider label.inner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--white);
  border-top-left-radius: var(--roundness) !important;
  border-bottom-left-radius: var(--roundness) !important;
  position: absolute;
  transition: all 0.15s cubic-bezier(0.5, 0.4, 0.2, 1);
  transform-origin: left;
}
#slider .emoji {
  margin-left: -0.2em;
  display: inline-block;
  width: 1em;
  height: 1em;
  transition: all 0.15s cubic-bezier(0.5, 0.4, 0.2, 1);
  border-radius: var(--roundness);
  text-align: right;
  font-size: calc(var(--roundness) * 2);
  line-height: 1.1;
}
</style>
