<template>
  <v-dialog v-model="showDetailsDialog" persistent width="450">
    <div class="dialogWrapper">
      <v-card v-if="!loader">
        <v-card-title class="primarygrad white--text">
          <v-card max-width="155" flat class="glass transparent">
            <v-text-field
              style="padding-top:0;padding:5px;text-align:center;"
              :prefix="currency"
              type="number"
              solo
              placeholder="Deal Value"
              v-model="editedItem.dealValue"
              :rules="dealValueRules"
              hide-details="auto"
            ></v-text-field
          ></v-card>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDetailsDialog = false">
            <v-icon icon="cancel" color="white" style="font-size: 1.6rem"
              >cancel</v-icon
            >
          </v-btn>
        </v-card-title>
        <v-card-text
          style="margin: 0px; padding: 0px 0px"
          class="primarygrad white--text"
        >
          <div>
            <div class="d-flex" style="padding-left:18px">
              <v-avatar
                size="81"
                class="mb-2 mr-2"
                :color="editedItem.name ? 'inverted' : ''"
              >
                <span v-if="editedItem.name" class="green--text text-h3">
                  {{ editedItem.name.substring(0, 1).toUpperCase() }}
                </span>
                <v-icon
                  v-else
                  icon="account_circle"
                  color="white"
                  style="font-size: 5rem"
                  >account_circle</v-icon
                >
              </v-avatar>

              <div class="">
                <div>
                  <div
                    class="d-flex"
                    style="font-weight: 500; padding: 6px;padding-top:12px;font-size:22px"
                  >
                    {{ editedItem.name ? editedItem.name : "Unknown" }}
                  </div>
                  <div>
                    <v-flex>
                      <v-chip
                        class="ml-1"
                        label
                        :color="getColor(editedItem.dispo)"
                        text-color="white"
                      >
                        <v-icon left>mdi-label</v-icon>
                        {{ computedDispo }}
                      </v-chip>
                      <v-menu
                        v-if="selectedList != 'allLists' && forms.length > 0"
                        close-on-content-click
                        bottom
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            class="ml-1"
                            dark
                            label
                            v-bind="attrs"
                            v-on="on"
                          >
                            FORM{{ forms.length > 1 ? "S" : null }}
                          </v-chip>
                        </template>

                        <v-list class="">
                          <v-list-item-group>
                            <v-list-item
                              v-for="(form, i) in forms"
                              :key="i"
                              @click="
                                $router.push({
                                  name: 'Form Response',
                                  params: {
                                    formId: form._id,
                                    contactId: editedItem.id,
                                  },
                                })
                              "
                            >
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="form.name"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-divider
            v-if="selectedContact != false && productList.length > 0"
          ></v-divider>

          <div v-if="selectedContact != false && productList.length > 0">
            <div class="d-flex" style="padding-left:18px">
              Products
            </div>
          </div>
          <div v-if="selectedContact != false && productList.length > 0">
            <div style="padding-left:18px">
              <span style="padding-bottom: 20px;">
                <v-chip
                  v-for="(product, i) in selectedContactProducts"
                  :key="i"
                  class="mr-2 mb-2"
                  close
                  label
                  :color="getProductColor(product)"
                  text-color="white"
                  @click:close="removeProduct(product)"
                >
                  <v-icon left>mdi-label</v-icon>
                  {{ product.title }}
                </v-chip>
              </span>

              <span style="padding-top: 20px;">
                <!-- <v-btn class="ml-1" color="primary" @click="addProductDialog">
                  <v-icon>mdi-plus</v-icon>
                </v-btn> -->

                <v-chip
                  color="primary"
                  class="mr-2 mb-2"
                  label
                  text-color="white"
                  @click="addProductDialog"
                >
                  <v-icon center>mdi-plus</v-icon>
                </v-chip>
              </span>

              <!-- <v-chip
                class="ml-1"
                label
                text-color="white"
                @click="addProductDialog"
              >
                <v-icon center>mdi-plus</v-icon>
                
              </v-chip> -->
            </div>
          </div>

          <div style="padding-bottom: 2%"></div>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-tabs background-color="transparent" color="basil" grow>
              <v-tab>
                <v-icon icon="account_circle" color="grey"
                  >account_circle</v-icon
                >
                <span v-if="selectedContact != false" style="padding-left: 2px"
                  >Detail</span
                >
                <span v-else style="padding-left: 2px">Add A Contact</span>
              </v-tab>
              <v-tab v-if="selectedContact != false">
                <v-icon icon="history" color="grey">history</v-icon>
                <span style="padding-left: 2px">History</span>
              </v-tab>
              <v-tab v-if="selectedContact != false">
                <v-icon icon="history" color="grey">mdi-file-check</v-icon>
                <span style="padding-left: 2px">Tasks</span>
              </v-tab>
              <v-tab-item>
                <div class="px-4" style="height: 38vh; overflow: auto;">
                  <v-flex xs12 sm12 md12 style="padding: 10px 0px">
                    <v-row style="padding: 0px; margin: 0px">
                      <v-flex
                        :class="`${selectedContact == false ? 'xs12' : 'xs10'}`"
                      >
                        <vue-tel-input
                          v-model="editedItem.phone"
                          mode="international"
                          :valid-characters-only="true"
                          :disabled="selectedContact != false"
                          :default-country="defaultCountry"
                          class="secondary--text"
                        ></vue-tel-input>
                      </v-flex>
                      <v-flex
                        v-if="selectedContact != false"
                        xs2
                        style="text-align: center"
                      >
                        <v-btn fab small color="green" @click="clickToCall">
                          <v-icon color="white">phone</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-row>
                  </v-flex>

                  <v-flex xs12 sm12 md12 style="padding: 10px 0px">
                    <vue-tel-input
                      v-model="editedItem.secondPhone"
                      :valid-characters-only="true"
                      placeholder="Secondary Phone"
                      class="secondary--text"
                      :default-country="defaultCountry"
                      @input="getSecondPhone"
                    ></vue-tel-input>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="editedItem.name"
                      label="Name"
                    ></v-text-field>
                  </v-flex>

                  <v-flex
                    v-if="
                      selectedContact != false &&
                        editedItem.dispo != 'NEW' &&
                        editedItem.dispo != 'DNC' &&
                        editedItem.dispo != 'CALLBACK' &&
                        editedItem.dispo != ''
                    "
                    xs12
                    sm12
                    md12
                  >
                    <v-select
                      v-model="editedItem.dispo"
                      :items="
                        dispoList.filter((d) => {
                          return (
                            !d.system ||
                            (!d.system && d.title == 'DNC') ||
                            (!d.system && d.title == 'CALLBACK')
                          );
                        })
                      "
                      label="Disposition"
                      item-text="title"
                      item-value="title"
                      required
                    ></v-select>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="listName"
                      label="List"
                      required
                      disabled
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <LeadScoreSlider :val.sync="editedItem.leadScore" />
                  </v-flex>

                  <!-- <v-flex xs12 sm12 md12>
                      <v-select
                        v-model="selectedProduct"
                        :items="productList"
                        item-text="name"
                        label="Select Products"
                        multiple
                        chips
                        persistent-hint
                      ></v-select>
                    </v-flex> -->

                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                      required
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="editedItem.companyName"
                      label="Company"
                      required
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="editedItem.address"
                      label="Address"
                      required
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="editedItem.extra"
                      label="Extra"
                      required
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      v-model="editedItem.remarks"
                      label="Remarks"
                      required
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-textarea
                      v-model="editedItem.note"
                      label="Note"
                      required
                    ></v-textarea>
                  </v-flex>

                  <v-flex
                    v-for="(field, i) in fieldList"
                    :key="i"
                    xs12
                    sm12
                    md12
                  >
                    <!-- Text Type -->
                    <v-text-field
                      v-if="
                        field.type != 'boolean' &&
                          drawCustomField &&
                          (field.type == 'text' || field.type == '')
                      "
                      v-model="editedItem['customFields'][field.name]"
                      :label="field.name"
                      :placehoder="field.placehoder"
                      :required="field.required"
                      @blur="
                        drawCustomField = false;
                        drawCustomField = true;
                      "
                    ></v-text-field>

                    <!-- Number Type -->
                    <v-text-field
                      v-if="
                        field.type != 'boolean' &&
                          drawCustomField &&
                          field.type == 'number'
                      "
                      v-model="editedItem['customFields'][field.name]"
                      :label="field.name"
                      :type="field.type"
                      :placehoder="field.placehoder"
                      :required="field.required"
                      @blur="
                        drawCustomField = false;
                        drawCustomField = true;
                      "
                    ></v-text-field>

                    <!-- Dropdown -->
                    <v-select
                      v-if="
                        field.type != 'boolean' &&
                          drawCustomField &&
                          field.type == 'mcq'
                      "
                      v-model="editedItem['customFields'][field.name]"
                      :items="field.mcqOptions"
                      :label="field.name"
                    />

                    <!-- Checkbox -->
                    <v-select
                      v-if="
                        field.type != 'boolean' &&
                          drawCustomField &&
                          field.type == 'checkbox'
                      "
                      v-model="editedItem['customFields'][field.name]"
                      chips
                      multiple
                      :items="field.checkboxOptions"
                      :label="field.name"
                    />
                    <!-- Radio Type -->
                    <!-- <v-radio-group
                        v-if="
                          field.type != 'boolean' &&
                            drawCustomField &&
                            field.type == 'mcq'
                        "
                        v-model="editedItem['customFields'][field.name]"
                      >
                        <p>{{ field.name }}</p>
                        <v-radio
                          v-for="mcq in field.mcqOptions"
                          :key="mcq"
                          :label="mcq"
                          :value="mcq"
                          color="primary"
                        ></v-radio>
                      </v-radio-group> -->

                    <!-- Date Type -->

                    <v-menu
                      v-if="
                        field.type != 'boolean' &&
                          drawCustomField &&
                          field.type == 'date'
                      "
                      :v-model="`${editCustomFieldDateMenu}_${i}`"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem['customFields'][field.name]"
                          :label="field.name"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem['customFields'][field.name]"
                        @input="editCustomFieldDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                </div>
                <!-- fixed buttons -->
                <div>
                  <v-row v-if="selectedContact != false" row wrap>
                    <v-col cols="6" class="pa-4">
                      <v-btn
                        block
                        depressed
                        color="error"
                        @click="deleteUser(editedItem)"
                        >Delete</v-btn
                      >
                    </v-col>
                    <v-col cols="6" class="pa-4">
                      <v-btn block depressed color="primary" @click="save"
                        >Update</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row v-else row wrap>
                    <v-col cols="6" class="pa-4">
                      <v-btn block depressed color="" @click="close"
                        >Cancel</v-btn
                      >
                    </v-col>
                    <v-col cols="6" class="pa-4">
                      <v-btn block depressed color="primary" @click="save"
                        >Add</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div style="height: 51vh; overflow: auto;">
                  <div v-if="timelineArray.length > 0">
                    <v-timeline
                      v-if="timelineArray != 'loading'"
                      :reverse="reverse"
                      dense
                    >
                      <v-timeline-item
                        v-for="item in timelineArray"
                        :key="item.id"
                        :color="item.color"
                        fill-dot
                        right
                        :icon="item.icon"
                      >
                        <v-card v-if="item.type == 'call'">
                          <v-card-title :class="item.color">
                            <h5
                              class="display-5 white--text font-weight-light"
                              style="font-size: 1.2rem"
                            >
                              {{ item.phone }}
                            </h5>
                          </v-card-title>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="12">
                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  Called On :
                                  {{ $moment(item.calledOn).format("lll") }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  Called By :
                                  {{
                                    item.accounts && item.accounts.name
                                      ? item.accounts.name
                                      : ""
                                  }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  List :
                                  {{
                                    item.list && item.list.name
                                      ? item.list.name
                                      : ""
                                  }}
                                </h5>

                                <h5
                                  v-if="item.note != ''"
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  Note : {{ item.note }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  <v-chip
                                    label
                                    :color="item.dispoColor"
                                    text-color="white"
                                  >
                                    <v-icon left>mdi-label</v-icon>
                                    {{ item.dispo }}
                                  </v-chip>

                                  <span
                                    style="padding: 5px;"
                                    v-if="
                                      item.recording && item.recording.success
                                    "
                                  >
                                    <v-btn
                                      text
                                      fab
                                      small
                                      @click="
                                        openDialogRecordPopup(item, 'phone')
                                      "
                                    >
                                      <v-icon style="opacity: 0.6" small
                                        >play_arrow</v-icon
                                      >
                                    </v-btn>
                                  </span>
                                </h5>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <v-card v-if="item.type == 'sms'">
                          <v-card-title :class="item.color">
                            <h5
                              class="display-5 white--text font-weight-light"
                              style="font-size: 1.2rem"
                            >
                              {{ item.phone }}
                            </h5>
                          </v-card-title>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="12">
                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  SMS On :
                                  {{ $moment(item.calledOn).format("lll") }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  List :
                                  {{
                                    item.list && item.list.name
                                      ? item.list.name
                                      : ""
                                  }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  SMS By :
                                  {{
                                    item.accounts && item.accounts.name
                                      ? item.accounts.name
                                      : ""
                                  }}
                                </h5>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <v-card v-if="item.type == 'email'">
                          <v-card-title :class="item.color">
                            <h5
                              class="display-5 white--text font-weight-light"
                              style="font-size: 1.2rem"
                            >
                              {{ item.phone }}
                            </h5>
                          </v-card-title>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="12">
                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  Email On :
                                  {{ $moment(item.calledOn).format("lll") }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  List :
                                  {{
                                    item.list && item.list.name
                                      ? item.list.name
                                      : ""
                                  }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  Email By :
                                  {{
                                    item.accounts && item.accounts.name
                                      ? item.accounts.name
                                      : ""
                                  }}
                                </h5>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <v-card v-if="item.type == 'link'">
                          <v-card-title :class="item.color">
                            <h5
                              class="display-5 white--text font-weight-light"
                              style="font-size: 1.2rem"
                            >
                              {{ item.phone }}
                            </h5>
                          </v-card-title>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="12">
                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  Send Via Link On :
                                  {{ $moment(item.calledOn).format("lll") }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  List :
                                  {{
                                    item.list && item.list.name
                                      ? item.list.name
                                      : ""
                                  }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  Send Via Link By :
                                  {{
                                    item.accounts && item.accounts.name
                                      ? item.accounts.name
                                      : ""
                                  }}
                                </h5>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <v-card v-if="item.type == 'app'">
                          <v-card-title :class="item.color">
                            <h5
                              class="display-5 white--text font-weight-light"
                              style="font-size: 1.2rem"
                            >
                              {{ item.phone }}
                            </h5>
                          </v-card-title>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="12">
                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  Send Via App On :
                                  {{ $moment(item.calledOn).format("lll") }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  List :
                                  {{
                                    item.list && item.list.name
                                      ? item.list.name
                                      : ""
                                  }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  Send Via App By :
                                  {{
                                    item.accounts && item.accounts.name
                                      ? item.accounts.name
                                      : ""
                                  }}
                                </h5>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>

                        <v-card v-if="item.type == 'note'">
                          <v-card-title :class="item.color">
                            <h5
                              class="display-5 white--text font-weight-light"
                              style="font-size: 1.2rem"
                            >
                              {{ item.phone }}
                            </h5>
                          </v-card-title>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="12">
                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  {{ item.note }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  List :
                                  {{
                                    item.list && item.list.name
                                      ? item.list.name
                                      : ""
                                  }}
                                </h5>

                                <h5
                                  class="grey--text font-weight-light"
                                  style="font-size: 0.8rem"
                                >
                                  Created By :
                                  {{
                                    item.accounts && item.accounts.name
                                      ? item.accounts.name
                                      : ""
                                  }}
                                </h5>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                    <v-skeleton-loader
                      v-else
                      type="list-item-two-line,list-item-two-line,list-item-two-line "
                    ></v-skeleton-loader>
                  </div>
                  <div v-else style="margin-top: 50px; margin-bottom: 20px">
                    <v-container fluid>
                      <v-card no-shadow style="background-color: transparent">
                        <v-card-text style="text-align: center">
                          <img
                            style="max-height: 120px; opacity: 0.7"
                            src="../assets/not-found.png"
                          />
                          <h3>
                            No History
                          </h3>
                        </v-card-text>
                      </v-card>
                    </v-container>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-container fluid style="height: 51vh; overflow: auto;">
                  <div v-if="taskLoader" class="text-center">
                    <v-skeleton-loader
                      type="list-item-two-line"
                    ></v-skeleton-loader>
                  </div>
                  <div
                    v-else-if="
                      !taskLoader &&
                        selectedContactTasks &&
                        selectedContactTasks.length > 0
                    "
                  >
                    <v-list>
                      <v-list-item
                        v-for="task in selectedContactTasks"
                        :key="task.id"
                        @click="updateTask(task, task.done)"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="task.done"></v-checkbox>
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>{{
                              task.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              task.desc
                            }}</v-list-item-subtitle>

                            <v-list-item-subtitle>{{
                              $moment(task.remindOn).format("lll")
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div v-else style="margin-top: 50px; margin-bottom: 20px">
                    <v-card no-shadow style="background-color: transparent">
                      <v-card-text style="text-align: center">
                        <img
                          style="max-height: 120px; opacity: 0.7"
                          src="../assets/not-found.png"
                        />
                        <h3>
                          No Tasks Found
                        </h3>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
      <v-card v-else>
        <v-skeleton-loader
          class="mx-auto"
          max-width="450"
          type="card-avatar, list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line, actions"
        ></v-skeleton-loader>
      </v-card>
    </div>

    <product-popup
      ref="productPopup"
      v-on:updatedContact="updatedContact"
    ></product-popup>

    <record-popup ref="recordPopup"></record-popup>
  </v-dialog>
</template>
<script>
import LeadScoreSlider from "../components/leadScoreSlider.vue";
import recordPopup from "../components/recordPopup.vue";
import productPopup from "../components/productPopup.vue";
import countries from "../js/countries";
import { mapGetters } from "vuex";
import fb from "../js/firebase";
import { sanitizeCustomFields } from "../js/util";
import validator from "../js/validator";
import ProductPopup from "./productPopup.vue";
import moment from "moment-timezone";
export default {
  components: {
    LeadScoreSlider,
    recordPopup,
    ProductPopup,
  },
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "user",
      "dispoList",
      "fieldList",
      "teams",
      "selectedTeam",
      "selectedList",
    ]),
    companyDetails() {
      return this.$store.getters.companyDetails;
    },
    computedDispo() {
      return this.editedItem.dispo != "" ? this.editedItem.dispo : "NEW";
    },
    defaultCountry() {
      const country = countries.find(
        (item) => item.name === this.$store.state.companyDetails.country
      );
      return country ? country.code : "IN";
    },
  },
  data() {
    return {
      productDialog: true,
      loader: false,
      cardWidth: "20",
      dialog: false,
      dispoName: "NEW",
      currency: this.$store.getters.companyDetails.currency,
      selectedContact: null,
      showDetailsDialog: false,
      editCustomFieldDateMenu: false,
      drawCustomField: true,
      addCustomFieldDateMenu: false,
      timelineArray: [],
      reverse: false,
      listName: "",
      dealValueRules: [
        (v) => parseInt(v) >= 0 || "Deal Value must be Zero or greater.",
        (v) => v.toString().length <= 9 || "Value must be less than 1 Billion",
      ],
      editedItem: {
        name: "",
        email: "",
        companyName: "",
        address: "",
        dispo: "",
        phone: "",
        secondPhone: "",
        remarks: "",
        extra: "",
        dealValue: "",
        leadScore: 60,
        customFields: [],
      },
      taskLoader: false,
      selectedContactTasks: [],
      selectedContactProducts: [],
      menu: false,
      forms: [],
      productList: [],
      // defaultCountry: "IN",
    };
  },
  mounted() {
    // this.defaultCountry = this.getCountryCode(
    //   this.$store.state.companyDetails.country
    // ); // Set default country name here
  },
  async created() {
    var self = this;
    await this.getProducts();
  },
  methods: {
    // getCountryCode(countryName) {
    //   const country = countries.find((item) => item.name === countryName);
    //   return country ? country.code : "IN";
    // },
    async removeProduct(product) {
      console.log(product);

      var self = this;

      const temp = this.selectedContactProducts.filter(
        (x) => x.id != product.id
      );

      var productsId = _.map(temp, "id");

      var postObj = {
        productsId: productsId,
        contactId: this.selectedContact.id,
      };

      this.$http
        .post(`${this.ENDPOINT}/products/set-product`, postObj)
        .then((response) => {
          this.selectedContactProducts = temp;
        });

      console.log(temp);
    },
    async showProducts() {},
    updatedContact: function(data) {
      this.editedItem = data;
      this.selectedContact = data;
      this.getProductsByContactId(this.selectedContact.id);
    },
    addProductDialog() {
      this.$refs.productPopup.openDialog(
        this.editedItem,
        this.selectedContactProducts
      );
    },
    close() {
      this.showDetailsDialog = false;
      this.$emit("refresh");
    },
    clickToCall() {
      this.$http
        .post(`${this.$store.state.ENDPOINT}/contacts/c2c`, {
          contact: this.selectedContact,
        })
        .then((response) => {
          //Todo
        })
        .catch((err) => {
          if (err.body.error && err.body.error.message) {
            this.$swal({ type: "error", text: err.body.error.message });
          } else {
            this.$swal({
              type: "error",
              text: "Something went wrong. Please try again later",
            });
          }
        });
    },
    // Get the number with country code
    getPhoneNumber(e, phone) {
      if (e) {
        return (this.editedItem.phone = phone.number.e164);
      } else {
        return (this.editedItem.phone = "");
      }
    },

    // delete contact
    deleteUser(contact) {
      this.loader = true;
      this.$http
        .delete(`${this.ENDPOINT}/contacts/${contact.id}`)
        .then((response) => {
          this.loader = false;

          this.$swal({ type: "success", text: "Successfully deleted" });
          this.close();
        })
        .catch((err) => {
          this.loader = false;
          this.$swal({ type: "error", text: "Something went wrong" });
        });
    },

    // asisgnment of secondary number
    getSecondPhone(event, phone) {
      // if (event) {
      //   return (this.editedItem.secondPhone = phone.number.e164);
      // } else {
      //   return (this.editedItem.secondPhone = "");
      // }
    },

    openDialogRecordPopup(item, dialingOpt) {
      if (dialingOpt == "exotel") {
        (this.selectedAudio = null), (this.selectedLog = null);
        if (typeof item.serverResponse.RecordingUrl != "undefined") {
          var audioExotel = new Audio(`${item.serverResponse.RecordingUrl}`);
          this.selectedAudio = audioExotel;

          console.log("item", item);

          this.selectedLog = item;
          this.audioDialog = true;
        }
      } else {
        console.log("this.$refs", this.$refs);

        this.$refs.recordPopup.openDialog(item);
      }
    },

    closeDialogRecordPopup() {
      this.$refs.recordPopup.closeDialog(false);
    },

    async getProducts() {
      var self = this;
      var filter = {
        where: {
          companyId: this.$store.getters.user.companyId,
        },
      };

      try {
        var response = await this.$http.get(
          `${self.$store.state.ENDPOINT}/products?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        );
        self.productList = response.body;

        console.log("self.productList", self.productList);

        // self.loader = false;
      } catch (err) {
        console.log("Error", err);

        // self.loader = false;

        self.$swal({
          type: "error",
          text: "Something went wrong. Please try again",
        });
      }
    },

    // add a new contact
    save() {
      this.loader = true;
      const self = this;
      if (this.editedItem.dealValue != "") {
        if (
          this.editedItem.dealValue < 0 ||
          this.editedItem.dealValue > 1000000000
        ) {
          this.loader = false;
          this.$swal({
            type: "warning",
            text: "Deal Value is less than zero or more than 1 Billion.",
          });
          return;
        }

        if (/[\d\.]+/i.test(this.editedItem.dealValue) == false) {
          this.loader = false;
          this.$swal({
            type: "warning",
            text: "Deal Value must be numeric",
          });
          return;
        }
      }

      if (this.editedItem.phone == "" || this.editedItem.phone == undefined) {
        this.loader = false;
        this.$swal({ type: "warning", text: "Phone is blank" });
        return;
      } else if (this.selectedList == "") {
        this.loader = false;
        this.$swal({ type: "warning", text: "Select a list" });
        return;
      }

      this.editedItem.phone = validator.phoneNumberValidator(
        this.editedItem.phone
      );

      if (this.editedItem.secondPhone != "") {
        this.editedItem.secondPhone = validator.phoneNumberValidator(
          this.editedItem.secondPhone
        );
      }

      this.editedItem.dispo =
        this.editedItem.dispo == "NEW" ? "" : this.editedItem.dispo;

      // this.editedItem.customFields = _.chain(this.editedItem.customFields)
      //   .keyBy("name")
      //   .mapValues("value")
      //   .value();

      //Validate the custom fields
      this.editedItem.customFields = sanitizeCustomFields(
        this.editedItem.customFields,
        self.fieldList
      );

      //dealvalue
      this.editedItem.dealValue =
        Math.round(this.editedItem.dealValue * 100) / 100;

      if (this.selectedContact != false) {
        var patchObj = {
          name: this.editedItem.name,
          phone: this.editedItem.phone,
          secondPhone: this.editedItem.secondPhone,
          dispo: this.editedItem.dispo,
          email: this.editedItem.email,
          companyName: this.editedItem.companyName,
          address: this.editedItem.address,
          remarks: this.editedItem.remarks,
          extra: this.editedItem.extra,
          note: this.editedItem.note,
          smsSent: this.editedItem.smsSent,
          emailSent: this.editedItem.emailSent,
          linkSent: this.editedItem.linkSent,
          appSent: this.editedItem.appSent,
          customFields: this.editedItem.customFields,
          dealValue: Number(this.editedItem.dealValue),
          leadScore: this.editedItem.leadScore,
          modifiedOn: new Date(),
          // products: this.selectedProduct,
        };

        this.$http
          .patch(`${this.ENDPOINT}/contacts/${this.editedItem.id}`, patchObj)
          .then((response) => {
            const obj = _.reduce(
              response.body.customFields,
              (result, { name, value }) => {
                if (moment(value, moment.ISO_8601, true).isValid()) {
                  // Check if value is a valid date
                  result[name] = moment(value).format("YYYY-MM-DD"); // Format date using Moment.js
                } else {
                  result[name] = value; // Use original value if not a date
                }
                return result;
              },
              {}
            );

            console.log(obj);

            response.body.customFields = obj;

            this.clearEditedItem(response.body);
            this.loader = false;
            this.$emit("refresh");
          })
          .catch((err) => {
            console.log("err", err);

            if (err.body.error.message.includes("is not unique")) {
              this.$swal({
                type: "error",
                text: "This phone number already exists in this list",
              });
            } else {
              this.$swal({ type: "error", text: err.body.error.message });
            }
            this.close();
          });
      } else {
        var postObj = {
          name: this.editedItem.name,
          phone: this.editedItem.phone,
          secondPhone: this.editedItem.secondPhone,
          email: this.editedItem.email,
          companyName: this.editedItem.companyName,
          address: this.editedItem.address,
          note: this.editedItem.note,
          remarks: this.editedItem.remarks,
          extra: this.editedItem.extra,
          companyId: this.user.companyId,
          listId: this.selectedList,
          accountsId: "",
          smsSent: false,
          emailSent: false,
          linkSent: false,
          appSent: false,
          dealValue: Number(this.editedItem.dealValue),
          customFields: this.editedItem.customFields,
          leadScore: this.editedItem.leadScore,
        };

        if (this.editedItem.dealValue == 0) {
          delete postObj.dealValue;
        }

        this.$http
          .post(`${this.ENDPOINT}/contacts`, postObj)
          .then((response) => {
            this.$swal({ type: "success", text: "Successful" });
            this.loader = false;
            this.close();
            this.$emit("refresh");
            fb.log("add_contact");
            this.clearEditedItem();
          })
          .catch((err) => {
            console.log("Create Contact Error", err);
            this.$swal({
              type: "error",
              text:
                "This contact already exists in the system or list. Kindly check your unique contact settings in Settings->General",
            });
            this.close();
          });
      }
    },
    async open(contact) {
      // contact.customFields = _.map(contact.customFields, (field, index) => {
      //   return { name: index, value: field };
      // });

      if (!this.currency) {
        this.currency = "₹";
      }
      this.loader = false;

      this.selectedContact = contact;
      // this.selectedProduct = contact.products;

      await this.$http
        .get(`${this.ENDPOINT}/lists/${this.selectedContact.listId}`)
        .then((response) => {
          this.listName = response.body.name;
        })
        .catch((e) => {
          console.log("e", e);
        });

      this.clearEditedItem(this.selectedContact);

      if (this.editedItem.dispo) {
        this.dispoName = this.editedItem.dispo;
      }

      if (!this.editedItem.dealValue) this.editedItem.dealValue = "";

      if (Math.abs(parseInt(this.editedItem.dealValue)) > 99999999) {
        this.cardWidth = "180";
      }
      if (Math.abs(parseInt(this.editedItem.dealValue)) > 99999) {
        this.cardWidth = "160";
      } else {
        this.cardWidth = "130";
      }

      this.showDetailsDialog = true;

      this.timelineArray = "loading";

      var filter = {
        where: { contactId: `${this.selectedContact.id}` },
        include: [
          {
            relation: "accounts",
            scope: {
              fields: ["id", "name"],
            },
          },
          {
            relation: "list",
            scope: {
              fields: ["id", "name"],
            },
          },
        ],
        fields: [
          "id",
          "accounts",
          "type",
          "accountsId",
          "listId",
          "list",
          "phone",
          "type",
          "dispo",
          "note",
          "calledOn",
          "truecallerCallRecording",
        ],
      };
      this.$http
        .get(
          `${this.$store.state.ENDPOINT}/logs?filter=${encodeURIComponent(
            JSON.stringify(filter)
          )}`
        )
        .then((response) => {
          console.log("recording available or not", response.body);

          var tmArray = response.body;
          var dispoList = this.dispoList;

          for (var x in tmArray) {
            if (tmArray[x].type == "call") {
              tmArray[x].icon = "phone";
              tmArray[x].color = "green lighten-1";

              tmArray[x].recording = tmArray[x].truecallerCallRecording;

              for (var y in dispoList) {
                if (dispoList[y].title === tmArray[x].dispo) {
                  tmArray[x].dispoColor = dispoList[y].hex;
                }
              }
            } else if (tmArray[x].type == "sms") {
              tmArray[x].icon = "textsms";
              tmArray[x].color = "red lighten-2";
            } else if (tmArray[x].type == "email") {
              tmArray[x].icon = "email";
              tmArray[x].color = "purple lighten-1";
            } else if (tmArray[x].type == "link") {
              tmArray[x].icon = "link";
              tmArray[x].color = "indigo lighten-1";
            } else if (tmArray[x].type == "app") {
              tmArray[x].icon = "apps";
              tmArray[x].color = "orange lighten-1";
            } else if (tmArray[x].type == "note") {
              tmArray[x].icon = "description";
              tmArray[x].color = "teal lighten-1";
            }
          }

          this.timelineArray = tmArray;

          console.log(
            "recording available or not this.timelineArray",
            this.timelineArray
          );

          // let temp = this.timelineArray.filter(
          //   (x) => x.dispo === this.editedItem.dispo
          // );
          // if (temp.length > 0) this.editedItem.dispoColor = temp[0].dispoColor;
          // else this.editedItem.dispoColor = "#2e6930";
        })
        .catch((e) => {
          this.timelineArray = [];
          this.showDetailsDialog = true;
          console.log("------------err---------------", e);
        });
      this.getForms(this.editedItem);

      try {
        if (contact != false) {
          await this.getProductsByContactId(this.selectedContact.id);
        }
      } catch (err) {
        console.log("error", err);
      }

      try {
        if (contact != false) {
          await this.getTasksByContactId(this.selectedContact.id);
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    clearEditedItem(payload) {
      this.editedItem = {
        name: "",
        email: "",
        companyName: "",
        dispo: "",
        phone: "",
        secondPhone: "",
        remarks: "",
        extra: "",
        leadScore: 60,
        customFields: {},
        modifiedOn: new Date(),
      };

      // _.each(this.fieldList, (l, i) => {
      //   this.editedItem.customFields[l.name] = "";
      // });

      if (payload) this.editedItem = _.merge(this.editedItem, payload);

      console.log("this.editedItem", this.editedItem);
    },
    getProductColor(product) {
      if (product.hex == undefined) {
        return "#E5E5E5";
      }
      return product.hex;
    },
    getColor(dispo) {
      const self = this;
      try {
        if (typeof dispo == "undefined" || dispo == "NEW" || dispo == "")
          return "primary";
        var d = _.find(self.dispoList, (d) => {
          return d.title == dispo;
        });
        if (d == undefined) {
          return "#E5E5E5";
        }
        return d.hex;
      } catch (e) {
        console.log("error", e);
      }
    },
    async getTasksByContactId(id) {
      try {
        this.taskLoader = true;
        var response = await this.$http.get(
          `${this.$store.state.ENDPOINT}/contacts/${id}/tasks`
        );
        if (response.status == 200) {
          this.taskLoader = false;
          this.selectedContactTasks = response.data;
        }
      } catch (e) {
        this.taskLoader = false;
        console.log(e);
      }
    },
    async getProductsByContactId(id) {
      try {
        var response = await this.$http.get(
          `${this.$store.state.ENDPOINT}/contacts/${id}/products`
        );
        if (response.status == 200) {
          this.selectedContactProducts = response.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    updateTask(task, done) {
      try {
        // console.log('Task Item Clicked', task, done);
        task.done == true ? (task.done = false) : (task.done = true);
        this.$http
          .patch(`${this.$store.state.ENDPOINT}/tasks/${task.id}`, task)
          .then((res) => {
            this.getTasksByContactId(this.selectedContact.id);
          })
          .catch((e) => {
            console.log("Update Task Contact Popup Error", e);
          });
      } catch (e) {
        console.log("Update Task Contact Popup Error", e);
      }
    },
    setDueDate(contact) {
      this.$http
        .patch(`${this.ENDPOINT}/contacts/${contact.id}`, contact)
        .then((res) => {
          this.editedItem = res.data;
          this.menu = false;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    async getForms(editedItem) {
      if (editedItem.listId) {
        var response = await this.$http.post(
          `${this.ENDPOINT}/forms/get-form-by-list`,
          { listsId: this.selectedList }
        );
        this.forms = response.body;
      }
    },
  },
};
</script>
<style>
.v-input__slot:before {
  border-color: rgb(0 0 0 / 2%) !important;
}
</style>
