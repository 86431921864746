var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"transparent text-no-wrap",attrs:{"dense":"","headers":_vm.headers,"items":_vm.contacts,"server-items-length":_vm.totalContacts,"options":_vm.options,"item-key":"id","loading":_vm.loadingTable,"show-select":"","footer-props":{
    itemsPerPageOptions: _vm.recordsPerPage,
  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dispo",fn:function(ref){
  var item = ref.item;
return [(item.dnc || item.dispo == 'DNC')?_c('v-chip',{staticStyle:{"margin":"5px"},attrs:{"label":"","color":_vm.getColor('DNC'),"text-color":"white","close":""},on:{"click:close":function($event){return _vm.rechurnDNC(item)}}},[_c('span',{staticStyle:{"font-size":"14px","color":"#fafafa","font-weight":"400"}},[_vm._v(" DNC ")])]):_c('v-chip',{staticStyle:{"margin":"5px"},attrs:{"label":"","color":_vm.getColor(item.dispo),"dark":""}},[(item.dispo == 'CALLBACK')?_c('span',{staticStyle:{"font-size":"14px","font-weight":"400"}},[_vm._v(" "+_vm._s(item.dispo)+" ("+_vm._s(_vm.$moment(item.callbackOn).format("lll"))+") ")]):_c('span',{staticStyle:{"font-size":"14px","font-weight":"400"}},[_vm._v(" "+_vm._s(item.dispo)+" ")])]),(item.smsSent)?_c('v-chip',{staticStyle:{"margin":"5px"},attrs:{"label":"","color":"yellow","text-color":"white"}},[_c('span',{staticStyle:{"font-size":"14px","color":"#fafafa","font-weight":"400"}},[_vm._v(" SMS ")])]):_vm._e(),(item.emailSent)?_c('v-chip',{staticStyle:{"margin":"5px"},attrs:{"label":"","color":"blue","text-color":"white"}},[_c('span',{staticStyle:{"font-size":"14px","color":"#fafafa","font-weight":"400"}},[_vm._v(" Email ")])]):_vm._e(),(item.linkSent)?_c('v-chip',{staticStyle:{"margin":"5px"},attrs:{"label":"","color":"green","text-color":"white"}},[_c('span',{staticStyle:{"font-size":"14px","color":"#fafafa","font-weight":"400"}},[_vm._v(" Link ")])]):_vm._e(),(item.appSent)?_c('v-chip',{staticStyle:{"margin":"5px"},attrs:{"label":"","color":"orange","text-color":"white"}},[_c('span',{staticStyle:{"font-size":"14px","color":"#fafafa","font-weight":"400"}},[_vm._v(" App ")])]):_vm._e()]}},{key:"item.leadScore",fn:function(ref){
  var item = ref.item;
return [(item.leadScore >= 0)?_c('v-chip',[_c('span',{staticClass:"happiness mr-2"},[_vm._v(" "+_vm._s(_vm.getHappiness(item.leadScore)))]),_c('span',{staticClass:"score"},[_vm._v(" "+_vm._s(item.leadScore)+" ")])]):_vm._e()]}},{key:"item.phone",fn:function(ref){
  var item = ref.item;
return [_c('a',{staticClass:"dotted-gradient secondary--text",attrs:{"text":""},on:{"click":function($event){return _vm.openDetailsDialog(item)}}},[_vm._v(_vm._s(item.phone))])]}},{key:"item.name",fn:function(ref){
  var item = ref.item;
return [_c('a',{staticClass:"dotted-gradient secondary--text",attrs:{"text":""},on:{"click":function($event){return _vm.openDetailsDialog(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.createdOn",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.createdOn).format("Do MMMM YYYY, h:mm A"))+" ")]}},{key:"item.dealValue",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency ? _vm.currency : "₹")+" "+_vm._s(item.dealValue != undefined ? item.dealValue : 0)+" ")]}}],null,true),model:{value:(_vm.mySelectedIds),callback:function ($$v) {_vm.mySelectedIds=$$v},expression:"mySelectedIds"}})}
var staticRenderFns = []

export { render, staticRenderFns }