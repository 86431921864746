<template>
  <div class="d-flex">
    <v-text-field
      v-model="searchtext"
      label="Phone / Name"
      prepend-inner-icon="mdi-magnify"
      dense
      flat
      hide-details
      solo-inverted
      clearable
      class="mt-1 customBorderRadius"
      @input="checkEmpty()"
      @click:clear="reset()"
    />
    <v-chip
      dark
      label
      class="ml-1 pa-5"
      @click="search()"
      style="background-image: linear-gradient(to right, #92df48, #1bca3f); "
    >
      <v-icon size="24">mdi-magnify</v-icon>
    </v-chip>
  </div>
</template>

<script>
export default {
  data() {
    return { searchtext: "" };
  },
  methods: {
    search() {
      this.$emit("searchCon", this.searchtext);
    },
    reset() {
      this.$emit("reset", "");
    },
    checkEmpty() {
      if (this.searchtext == "") {
        this.reset();
      }
    },
    searchReset() {
      this.searchtext = "";
    },
  },
};
</script>

<style></style>
