<template>
  <div>
    <v-row>
      <v-col></v-col>
      <v-col class="text-center ma-auto flex-grow-0" style="text-align:center">
        <v-img :src="fileInputPic" style="width: 130px;opacity: 0.6;" />
      </v-col>
      <v-col> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-progress-linear v-model="progress" class="mb-2" height="15">
          <strong>{{ Math.ceil(progress) }}%</strong>
        </v-progress-linear>
        <!-- {{ status.processed * 100 }} / {{ status.totalContacts }} -->
      </v-col>
      <v-col cols="12" class="text-center">
        <p class="subheading" style="font-size: 14px;font-weight: 400;">
          {{ calcStatus }}
        </p>
        <p class="subheading" style="font-size: 12px;font-weight: 200;">
          {{ elapsedTime }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert small type="success">You will receive an email once the file has completed
          processing</v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["batchId"],
  data() {
    return {
      status: {
        processed: 0,
        chunkLength: 0,
      },
      intervalId: null,
    };
  },
  mounted() {
    this.checkStatus();
  },
  computed: {
    ...mapGetters(["ENDPOINT"]),
    progress() {
      if (this.status.chunkLength == 0 && this.status.processed == 0) return 0;

      return (this.status.processed / this.status.chunkLength) * 100;
    },
    importSuccessfully() {
      return require("../assets/importSuccessfully.png");
    },
    fileInputPic() {
      return require("../assets/fileinput.png");
    },
    calcStatus() {
      switch (this.status.status) {
        case "completed":
          return "Contacts have been processed. You can close this window and refresh the page.";
        case "processing":
          return "Contacts are being processed";
        case "started":
          return "Contacts added to queue for processing";

        default:
          return "Contacts waiting to be added to queue";
      }
    },
    elapsedTime() {
      if (this.status.completed)
        return `Finished at: ${new Date(this.status.endTime).toLocaleString()}`;

      const diff = new Date() - new Date(this.status.startTime);
      return `Elapsed Time: ${Math.floor(diff / 1000)} seconds`;

      //   if (this.status.status == "completed") {
      //     return "";
      //   } else if (
      //     this.status.status == "processing" ||
      //     this.status.status == "started"
      //   ) {
      //     return `Elapsed Time: ${Math.floor(diff / 1000)} seconds`;
      //   } else {
      //     return "";
      //   }
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    async checkStatus() {
      const self = this;
      //   alert("test");
      this.intervalId = setInterval(async () => {
        const response = await this.$http.get(
          `${this.ENDPOINT}/bulkBatchStatuses/${self.batchId}`
        );
        console.log(response);
        this.status = response.body;
        if (this.status.status === "completed") {
          // this.refresh();
          clearInterval(this.intervalId);
        }
      }, 2500);
    },
  },
};
</script>
