<template>
  <v-container fluid text-xs-center>
    <v-layout row wrap style="margin: 0px">
      <v-flex class="align" xs12 style="padding: 5px">
        <v-card text-xs-center elevation="0">
          <!-- Container One -->
          <v-layout row wrap style="padding: 4%">
            <!-- <v-flex xs12 md12 sm12 d-flex justify-center>
              <v-avatar size="90">
                <img :src="computeLogo" alt="GoDial" />
              </v-avatar>
            </v-flex> -->

            <v-flex
              class="form-container"
              xs12
              md12
              sm12
              d-flex
              justify-center
              style="padding: 2%"
            >
              <!-- Sending data and function as props -->
              <Htmlcontainer
                :html-data="htmlData"
                :is-updated="isUpdated"
                :set-value="setValue"
                :set-options="setOptions"
                :item-nameand-color="itemNameandColor"
              ></Htmlcontainer>
            </v-flex>
          </v-layout>

          <v-divider v-if="formId && formId.length"></v-divider>
          <!-- Container Two where link of the page will show -->
          <v-layout
            v-if="formId && formId.length"
            row
            wrap
            class="link-container"
            style="padding: 4%"
          >
            <v-flex xs12 md12 sm12 class="link-inner-one">
              <v-text-field
                v-model="gopageLink"
                disabled
                label="Webpage Link"
                type="text"
                outlined
                dense
                prepend-inner-icon="link"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12 sm12 class="link-inner-two">
              <v-btn color="primarygrad" @click="copyText">
                Copy
              </v-btn>
            </v-flex>
          </v-layout>

          <!-- <v-divider></v-divider> -->

          <!-- Container Three -->
          <!-- <v-layout row wrap style="padding: 4%">
            <v-flex xs12 md12 sm12 d-flex justify-center>
              <v-text-field v-model="htmlData.companyName" label="Company Name" type="text" outlined dense
                @input="handleChange"></v-text-field>
            </v-flex>
            <v-flex xs12 md12 sm12 d-flex justify-center>
              <v-text-field v-model="htmlData.desc" label="Description" type="text" outlined dense
                @input="handleChange"></v-text-field>
            </v-flex>
            <v-flex xs12 md12 sm12 d-flex justify-center>
              <v-text-field v-model="htmlData.itemOne" label="List Item One" type="text" outlined dense
                @input="handleChange"></v-text-field>
            </v-flex>
            <v-flex xs12 md12 sm12 d-flex justify-center>
              <v-text-field v-model="htmlData.itemTwo" label="List Item Two" type="text" outlined dense
                @input="handleChange"></v-text-field>
            </v-flex>
            <v-flex xs12 md12 sm12 d-flex justify-center>
              <v-text-field v-model="htmlData.itemThree" label="List Item Three" type="text" outlined dense
                @input="handleChange"></v-text-field>
            </v-flex>
            <v-flex xs12 md12 sm12 align-self-center>
              <p>Form Fields</p>
              <v-checkbox v-model="htmlData.nameEnabled" label="Name" color="success"></v-checkbox>
              <v-checkbox v-model="htmlData.emailEnabled" label="Email" color="success"></v-checkbox>
              <v-checkbox v-model="htmlData.phoneEnabled" label="Phone" color="success"></v-checkbox>
            </v-flex>
            <v-flex xs12 md12 sm12 class="link-inner-two">
              <v-btn color="primarygrad" @click="handleChange">
                Save
              </v-btn>
            </v-flex>
          </v-layout> -->
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Htmlcontainer from "../components/Htmlcontainer.vue";
export default {
  components: { Htmlcontainer },
  props: ["itemNameandColor"],
  data() {
    return {
      htmlData: {
        highlightItems: [
          {
            icon: "check",
            text: "Know how it works.",
          },
          {
            icon: "check",
            text: "What are the key benefits of company name",
          },
        ],
        title: "Welcome to company name",
        subHeading:
          "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages",
        submitButtonText: "Submit",
        termsAndConditionsUrl: "",
        privacyPolicyUrl: "",
        nameEnabled: true,
        emailEnabled: true,
        phoneEnabled: true,
        addressEnabled: false,
        companyNameEnabled: false,
        nameInputText: "What is your full name?",
        emailInputText: "What is your email id?",
        contactInputText: "What is your contact number?",
        addressInputText: "What is your address?",
        companyInputText: "What is your company name?",
        formTitle: "Fill in the details",
        declarationTextOne: "I have read and agreed to the following",
        declarationTextTwo: "Terms and Conditions",
        declarationTextThree: "and",
        declarationTextFour: "Privacy Policy",
        logoLink: "",
        backgroundLink: "",
        themeColor: "",
      },
      isUpdated: false,
      formId: "",
      gopageLink: "",
    };
  },
  // computed: {
  //   companyDetails() {
  //     return this.$store.getters.companyDetails;
  //   },
  //   computeLogo() {
  //     return require("../assets/logo.png");
  //   },
  // },
  mounted() {
    console.log("itemNameandColor ............", this.itemNameandColor);

    if (this.itemNameandColor && this.itemNameandColor.themeColor) {
      this.htmlData.themeColor = this.itemNameandColor.themeColor;
    }

    this.formId = this.$route.params.id;
    if (this.formId) {
      this.$http
        .get(`${this.$store.state.ENDPOINT}/integrateForms/${this.formId}`)
        .then((res) => {
          console.log("Response 1111111111111111111111111111111111", res);

          // setting the details of the form
          this.htmlData.highlightItems = res.body.content[0].highlightItems;
          this.htmlData.title = res.body.content[0].title;
          this.htmlData.subHeading = res.body.content[0].subHeading;
          this.htmlData.submitButtonText =
            res.body.formField[0].submitButtonText;
          this.htmlData.nameInputText = res.body.formField[0].nameInputText;
          this.htmlData.emailInputText = res.body.formField[0].emailInputText;
          this.htmlData.contactInputText =
            res.body.formField[0].contactInputText;
          this.htmlData.addressInputText =
            res.body.formField[0].addressInputText;
          this.htmlData.companyInputText =
            res.body.formField[0].companyInputText;
          this.htmlData.nameEnabled = res.body.formField[0].nameEnabled;
          this.htmlData.formTitle = res.body.formField[0].formTitle;
          this.htmlData.declarationTextOne =
            res.body.formField[0].declarationTextOne;
          this.htmlData.declarationTextTwo =
            res.body.formField[0].declarationTextTwo;
          this.htmlData.declarationTextThree =
            res.body.formField[0].declarationTextThree;
          this.htmlData.declarationTextFour =
            res.body.formField[0].declarationTextFour;
          this.htmlData.logoLink = res.body.logoLink ? res.body.logoLink : "";
          this.htmlData.backgroundLink = res.body.backgroundLink
            ? res.body.backgroundLink
            : "";

          // console.log("this.htmlData.nameEnabled", this.htmlData.nameEnabled)

          this.htmlData.emailEnabled = res.body.formField[0].emailEnabled;
          this.htmlData.phoneEnabled = res.body.formField[0].phoneEnabled;
          this.htmlData.companyNameEnabled =
            res.body.formField[0].companyNameEnabled;
          this.htmlData.addressEnabled = res.body.formField[0].addressEnabled;
          this.htmlData.themeColor = res.body.themeColor
            ? res.body.themeColor
            : "";
          this.gopageLink = `https://enterprise.godial.cc/gopages/${res.body.name}`;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        }); //if error occurs
    }
    // else {
    //   window.history.back();
    // }
  },
  methods: {
    // scrollToTop() {
    //   window.scrollTo(0, 0);
    // },
    copyText() {
      navigator.clipboard.writeText(this.gopageLink);
    },
    handleChange() {
      // console.log("updated data", this.htmlData);
      this.isUpdated = true;
    },
    setValue(input, value) {
      switch (input) {
        case "Company Name":
          this.htmlData.title = value;
          break;
        case "Description":
          this.htmlData.subHeading = value;
          break;

        case "Items":
          this.htmlData.highlightItems = value;
          break;

        default:
          break;
      }
    },
    setOptions(data) {
      console.log("data", data);

      this.htmlData = { ...this.htmlData, data };
    },
    addItems(value) {
      this.htmlData.highlightItems.push = value;
    },
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 2px !important;
  margin: 5px;
  padding: 1.2rem;
}

.form-container {
  width: 100%;
}

.link-container {
  margin-top: 1rem;
}

/* .link-container {
  display: flex;
  align-items: center;
} */

/* .link-inner-one {
  min-width: 93%;
}

.link-inner-two {
  margin-left: 1rem;
}

@media only screen and (max-width: 600px) {
  .link-inner-one {
    display: block;
  }

  .link-inner-two {
  }
} */
</style>
