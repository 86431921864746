<template>
  <div class="html-container">
    <p style="font-weight: 600;">Choose List</p>

    <v-flex>
      <v-row>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="listId"
            class="pt-1"
            :items="lists"
            item-text="name"
            item-value="id"
            label="Lists"
            prepend-inner-icon="view_list"
            autocomplete="false"
            outlined
            dense
          />
        </v-col>

        <v-col v-if="typeof formId == 'undefined'" cols="12" md="9">
          <a @click="openAddList">
            <v-icon style="font-size: 50px;color: #00CF46;"
              >mdi-plus-circle</v-icon
            >

            Create a new list
          </a>
        </v-col>
        <v-col v-else class="customPaddingForColor">
          <span style="font-weight: 600;">Choose theme color </span>
          <div class="d-flex mb-5" style="padding-top: 22px;">
            <v-avatar
              v-for="color in colors"
              :key="color"
              :color="color"
              :class="
                htmlData.themeColor == color ? 'active-border color-avatar' : ''
              "
              class="mx-1 color-avatar"
              size="25"
              @click="selectColor(color)"
            />
          </div>
        </v-col>
      </v-row>
    </v-flex>

    <v-flex xs12 sm12 md8> </v-flex>
    <!-- <iframe ref="iframe" width="100%" height="100%" frameborder="0"></iframe> -->
    <!--  ! This is the popup dialog part that will open when edit icon clicked -->
    <!-- Dialog box that will open onClick -->
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <template>
        <v-card>
          <v-toolbar color="primary" dark>{{ activeId }}</v-toolbar>
          <v-card-text class="text-container">
            <!-- Input Fields that will open for Title, sub heading -->
            <v-flex
              v-if="!isForm && !isList"
              xs12
              md12
              sm12
              d-flex
              justify-center
            >
              <v-text-field
                v-model="activeValue"
                :label="activeId"
                type="text"
                outlined
                dense
              ></v-text-field>
            </v-flex>

            <!-- <v-flex v-else-if="!isForm && !isList && isLogo" xs12 md12 sm12 d-flex justify-center>
              <v-text-field v-model="activeValue" :label="activeId" type="text" outlined dense></v-text-field>
            </v-flex> -->

            <!-- Input Fields to change or add list items -->
            <v-flex
              v-else-if="!isForm && isList"
              class="flex-column"
              xs12
              md12
              sm12
              d-flex
              justify-center
            >
              <v-layout
                v-for="(item, index) in htmlData.highlightItems"
                :key="index"
              >
                <v-flex d-flex align-self-center>
                  <v-text-field
                    v-model="htmlData.highlightItems[index].text"
                    label="Content"
                    type="text"
                    outlined
                    dense
                  ></v-text-field>
                  <v-btn
                    color="primarygrad"
                    @click="htmlData.highlightItems.splice(index, 1)"
                  >
                    -
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-btn
                class="margin-top"
                color="primarygrad"
                @click="
                  htmlData.highlightItems.push({ icon: 'check', text: '' })
                "
              >
                +
              </v-btn>
            </v-flex>

            <!-- Input field and chekcbox to disable and enable form components -->
            <v-flex
              v-else-if="isForm && !isList"
              xs12
              md12
              sm12
              d-flex
              flex-column
            >
              <v-flex xs12 md12 sm12 d-flex justify-space-around>
                <v-checkbox
                  v-model="htmlData.nameEnabled"
                  class="margin-right"
                  label="Name"
                  color="success"
                ></v-checkbox>
                <v-checkbox
                  v-model="htmlData.emailEnabled"
                  class="margin-right"
                  label="Email"
                  color="success"
                ></v-checkbox>
                <v-checkbox
                  v-model="htmlData.phoneEnabled"
                  class="margin-right"
                  label="Phone"
                  color="success"
                ></v-checkbox>
                <v-checkbox
                  v-model="htmlData.addressEnabled"
                  class="margin-right"
                  label="Address"
                  color="success"
                ></v-checkbox>
                <v-checkbox
                  v-model="htmlData.companyNameEnabled"
                  class="margin-right"
                  label="Company Name"
                  color="success"
                ></v-checkbox>
              </v-flex>
              <v-text-field
                v-model="htmlData.formTitle"
                class="my-1"
                label="Form heading"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.nameInputText"
                class="my-1"
                label="Name heading"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.emailInputText"
                class="my-1"
                label="Email heading"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.contactInputText"
                class="my-1"
                label="Contact heading"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.addressInputText"
                class="my-1"
                label="Address heading"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.companyInputText"
                class="my-1"
                label="Company heading"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.submitButtonText"
                class="my-1"
                label="Button heading"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.declarationTextOne"
                class="my-1"
                label="Declaration Text Part One"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.declarationTextTwo"
                class="my-1"
                label="Declaration Text Part Two"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.declarationTextThree"
                class="my-1"
                label="Declaration Text Part Three"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.declarationTextFour"
                class="my-1"
                label="Declaration Text Part Four"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.termsAndConditionsUrl"
                class="my-1"
                label="Terms and Conditions URL"
                type="text"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="htmlData.privacyPolicyUrl"
                class="my-1"
                label="Privacy Policy URL"
                type="text"
                outlined
                dense
              ></v-text-field>
            </v-flex>
          </v-card-text>

          <!-- Buttons to save inputs and send it to backend -->
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="
                `${!isForm} ? ${setInputValue(
                  activeId,
                  activeValue
                )} : ${saveForm}`
              "
              >Save</v-btn
            >
            <v-btn text @click="handleClose">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <add-list
      :is-open="isDialogHandling"
      :is-dialog-open="isDialogOpen"
    ></add-list>

    <v-container fluid style="padding-top: 5%;">
      <v-row justify="center">
        <!-- <v-card ref="card" row wrap class="main-container"></v-card> -->
        <v-layout class="main-container" :style="backgroundStyle">
          <v-flex class="leftCol">
            <div
              class="d-flex align-center justify-space-between mb-2 button-card"
            >
              <div class="d-flex align-center">
                <v-btn
                  class="mr-2"
                  elevation="2"
                  color="#00CF46"
                  fab
                  outlined
                  small
                  @click="openLogoDialog"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <p class="image-btn-text ma-0">Upload Your Brand Logo</p>
              </div>
              <div class="d-flex align-center">
                <v-btn
                  class="mr-2"
                  elevation="2"
                  color="#00CF46"
                  fab
                  outlined
                  small
                  @click="openBgDialog"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <p class="image-btn-text ma-0">Select Backgroud Image</p>
              </div>
              <!-- Hidden v-file-input for logo -->
              <v-file-input
                ref="logoInput"
                :rules="rules"
                accept="image/*"
                style="display: none"
                @change="uploadLogo"
              ></v-file-input>
              <!-- Hidden v-file-input for background -->
              <v-file-input
                ref="bgInput"
                :rules="rules"
                accept="image/*"
                style="display: none"
                @change="uploadBg"
              ></v-file-input>
            </div>
            <v-card class="left-card">
              <!-- Logo -->
              <div v-if="htmlData.logoLink.length" class="logo-container mb-3">
                <img :src="htmlData.logoLink" alt="logo" />
              </div>
              <!-- Title -->
              <v-badge bordered color="primary" overlap inline tile>
                <!-- Added getId to icon and component both so that user can click anywhere and popoup will Open -->
                <v-icon
                  slot="badge"
                  style="z-index: 1;"
                  @click.stop="getId('Company Name')"
                >
                  mdi-pencil
                </v-icon>
                <v-card-title
                  class="content-title"
                  @click.stop="getId('Company Name')"
                  >{{ htmlData.title }}</v-card-title
                >
              </v-badge>
              <!-- Description -->
              <v-badge
                bordered
                color="primary"
                icon="mdi-pencil"
                overlap
                inline
                tile
              >
                <v-icon
                  slot="badge"
                  style="z-index: 1;"
                  @click.stop="getId('Description')"
                >
                  mdi-pencil
                </v-icon>
                <v-card-subtitle
                  class="content-desc"
                  @click.stop="getId('Description')"
                  >{{ htmlData.subHeading }}</v-card-subtitle
                >
              </v-badge>
              <!-- List Items -->
              <v-badge bordered color="primary" overlap tile>
                <v-icon slot="badge" style="z-index: 1;" @click.stop="getList">
                  mdi-pencil
                </v-icon>
                <v-list class="list-container">
                  <!-- item -->
                  <v-list-item
                    v-for="(item, index) in htmlData.highlightItems"
                    :key="index"
                    class="d-flex flex-row justify-start align-center"
                    @click.stop="getList"
                  >
                    <!-- item -->
                    <v-list-item-icon>
                      <font-awesome-icon
                        icon="fa-solid fa-circle-chevron-right"
                      />
                    </v-list-item-icon>
                    <v-list-item-content v-text="item.text">
                    </v-list-item-content>
                  </v-list-item>

                  <!-- item -->
                  <!-- <v-badge bordered color="primary" overlap inline tile>
                  <v-icon slot="badge" style="z-index: 1;" @click.stop="getId('Item Two')">
                    mdi-pencil
                  </v-icon>
                  <v-list-item class="d-flex flex-row justify-start align-center" @click.stop="getId('Item Two')">
                    <v-list-item-icon>
                      <font-awesome-icon icon="fa-solid fa-circle-chevron-right" /> </v-list-item-icon>
                    <v-list-item-content v-text="htmlData.itemTwo">
                    </v-list-item-content>
                  </v-list-item>
                </v-badge> -->

                  <!-- item -->
                  <!-- <v-badge bordered color="primary" overlap inline tile>
                  <v-icon slot="badge" style="z-index: 1;" @click.stop="getId('Item Three')">
                    mdi-pencil
                  </v-icon>
                  <v-list-item class="d-flex flex-row justify-start align-center" @click.stop="getId('Item Three')">
                    <v-list-item-icon>
                      <font-awesome-icon icon="fa-solid fa-circle-chevron-right" /> </v-list-item-icon>
                    <v-list-item-content v-text="htmlData.itemThree">
                    </v-list-item-content>
                  </v-list-item>
                </v-badge> -->
                </v-list>
              </v-badge>
            </v-card>
          </v-flex>
          <!-- Form -->
          <v-flex class="rightCol">
            <v-badge class="badge" bordered color="primary" overlap tile>
              <v-icon slot="badge" style="z-index: 1;" @click="getOptions">
                mdi-pencil
              </v-icon>
              <form class="mainForm" @click="getOptions">
                <h2 id="form-header" class="identity">
                  {{
                    htmlData.formTitle
                      ? htmlData.formTitle
                      : "Fill in the details"
                  }}
                </h2>
                <!-- Name -->
                <label
                  id="name-label"
                  :class="`${!htmlData.nameEnabled && 'display-none'} identity`"
                  for="name"
                  >{{
                    htmlData.nameInputText
                      ? htmlData.nameInputText
                      : "What is your full name?"
                  }}</label
                >
                <input
                  id="name"
                  :class="`${!htmlData.nameEnabled && 'display-none'} identity`"
                  type="text"
                  name="name"
                />
                <!-- Email -->
                <label
                  id="name-label"
                  :class="
                    `${!htmlData.emailEnabled && 'display-none'} identity`
                  "
                  for="name"
                  >{{
                    htmlData.emailInputText
                      ? htmlData.emailInputText
                      : "What is your email id?"
                  }}</label
                >
                <input
                  id="name"
                  :class="
                    `${!htmlData.emailEnabled && 'display-none'} identity`
                  "
                  type="email"
                  name="email"
                />
                <!-- Phone -->
                <label
                  id="name-label"
                  :class="
                    `${!htmlData.phoneEnabled && 'display-none'} identity`
                  "
                  for="name"
                  >{{
                    htmlData.contactInputText
                      ? htmlData.contactInputText
                      : "What is your contact number?"
                  }}</label
                >
                <input
                  id="name"
                  :class="
                    `${!htmlData.phoneEnabled && 'display-none'} identity`
                  "
                  type="text"
                  name="name"
                />
                <!-- Address -->
                <label
                  id="name-label"
                  :class="
                    `${!htmlData.addressEnabled && 'display-none'} identity`
                  "
                  for="name"
                  >{{
                    htmlData.addressInputText
                      ? htmlData.addressInputText
                      : "What is your address?"
                  }}</label
                >
                <input
                  id="name"
                  :class="
                    `${!htmlData.addressEnabled && 'display-none'} identity`
                  "
                  type="text"
                  name="address"
                />
                <!-- Company name -->
                <label
                  id="name-label"
                  :class="
                    `${!htmlData.companyNameEnabled && 'display-none'} identity`
                  "
                  for="name"
                  >{{
                    htmlData.companyInputText
                      ? htmlData.companyInputText
                      : "What is your company name?"
                  }}</label
                >
                <input
                  id="name"
                  :class="
                    `${!htmlData.companyNameEnabled && 'display-none'} identity`
                  "
                  type="text"
                  name="companyname"
                />

                <v-flex class="my-5" d-flex justify-start align-start>
                  <v-checkbox
                    class="input-checkbox"
                    input-value="true"
                    :color="
                      htmlData.themeColor ? htmlData.themeColor : '#37cf37'
                    "
                    value
                    disabled
                  ></v-checkbox>
                  <p class="declaration">
                    {{
                      htmlData.declarationTextOne
                        ? htmlData.declarationTextOne
                        : "I have read and agreed to the following"
                    }}
                    <a
                      :href="htmlData.termsAndConditionsUrl"
                      :style="{ color: htmlData.themeColor || '#37cf37' }"
                      onclick="event.preventDefault()"
                      >{{
                        htmlData.declarationTextTwo
                          ? htmlData.declarationTextTwo
                          : "Terms and Conditions"
                      }}</a
                    >
                    {{
                      htmlData.declarationTextThree
                        ? htmlData.declarationTextThree
                        : "and"
                    }}
                    <a
                      :href="htmlData.privacyPolicyUrl"
                      :style="{ color: htmlData.themeColor || '#37cf37' }"
                      onclick="event.preventDefault()"
                      >{{
                        htmlData.declarationTextFour
                          ? htmlData.declarationTextFour
                          : "Privacy Policy"
                      }}</a
                    >
                  </p>
                </v-flex>
                <!-- <v-flex d-flex justify-start align-center>
                  <v-checkbox input-value="true" color="success" value disabled></v-checkbox>
                  <p class="declaration">I have read the following <a :href="htmlData.privacyPolicyUrl"
                      onclick="event.preventDefault()">Privacy Policy</a>
                  </p>
                </v-flex> -->

                <v-btn
                  id="submitButton"
                  type="button"
                  value="Submit"
                  :color="htmlData.themeColor ? htmlData.themeColor : '#37cf37'"
                  class="buttonForm identity"
                >
                  {{ htmlData.submitButtonText }}
                </v-btn>
              </form>
            </v-badge>
          </v-flex>
        </v-layout>
      </v-row>
    </v-container>
    <!-- <v-btn class="margin-top" color="primarygrad" @click="saveLeadForm">
      {{ formId ? "Update" : "Save" }}
    </v-btn> -->
    <div style="padding-top: 2%;">
      <v-btn
        color="#00CF46"
        class="white--text mr-3 text-transform"
        @click="saveLeadForm"
      >
        Save
      </v-btn>
      <v-btn
        outlined
        color="#00CF46"
        class="text-transform"
        @click="handleClose"
      >
        Reset
      </v-btn>
    </div>

    <!-- <v-text-field v-model="activeId" type="text"></v-text-field> -->
  </div>
</template>

<script>
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import specific icons */
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

import { mapGetters } from "vuex";

import AddList from "@/components/addList.vue";

/* add icons to the library */
library.add(faCircleChevronRight);

export default {
  components: {
    AddList,
  },
  props: {
    htmlData: {
      type: Object,
      required: true,
    },
    setValue: {
      type: Function,
      required: true,
    },
    setOptions: {
      type: Function,
      required: true,
    },
    itemNameandColor: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      form: {
        color: "", // Initially no color selected
      },
      website: "",
      pageName: "",
      color1: "",
      colors: [
        "red",
        "pink",
        "purple",
        "indigo",
        "blue",
        "green",
        "yellow",
        "amber",
        "brown",
        "black",
      ],
      isDialogOpen: false,
      editedIndex: -1,
      listItem: {
        name: "",
        teamsId: "",
        desc: "",
      },
      htmlContent: [],
      logo: "",
      dialog: false,
      activeId: "", // to track which edit part is selected to edit
      activeValue: "",
      isForm: false, // This is for the fill form part only which control the form fields
      isList: false, // this is the freatures of company part
      listId: "",
      lists: [],
      formId: "",
      themeId: "layoutOne",
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2 MB!",
      ],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
    backgroundStyle() {
      console.log(
        "computed this.htmlData.backgroundLink: ",
        this.htmlData.backgroundLink
      );
      return {
        backgroundImage: this.htmlData.backgroundLink.length
          ? `url('${this.htmlData.backgroundLink}')`
          : "",
        backgroundSize: "cover",
        backgroundPosition: "center center",
      };
    },
  },
  watch: {
    htmlData: {
      deep: true,
      immediate: true,
      handler(newData) {
        console.log("htmlData updated:", newData);
        // Call the renderHTML method or any other logic to update the rendered HTML based on the new data
        // this.renderHTML();
      },
    },
  },
  mounted() {
    var self = this;
    if (typeof self.formId != "undefined") {
      console.log("this.htmlData 1233", self.htmlData.themeColor);
      self.color1 = self.htmlData.themeColor;
      console.log("this.form.color", self.color1);
    }
  },
  created() {
    this.fetchLists();
    this.formId = this.$route.params.id;

    console.log("this.formId", this.formId);
  },

  methods: {
    openLogoDialog() {
      this.$refs.logoInput.$el.querySelector('input[type="file"]').click();
    },
    openBgDialog() {
      this.$refs.bgInput.$el.querySelector('input[type="file"]').click();
    },

    async uploadLogo(file) {
      if (file) {
        const uploadResponse = await this.uploadFile(file);
        await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay
        //this.htmlData.logoLink = `https://storage.googleapis.com/godial-enterprise.appspot.com/${uploadResponse.name}`;
        this.$set(
          this.htmlData,
          "logoLink",
          `https://storage.googleapis.com/godial-enterprise.appspot.com/${uploadResponse.name}`
        );
      }
    },
    async uploadBg(file) {
      if (file) {
        const uploadResponse = await this.uploadFile(file);
        await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay
        this.$set(
          this.htmlData,
          "backgroundLink",
          `https://storage.googleapis.com/godial-enterprise.appspot.com/${uploadResponse.name}`
        );
        console.log("Updated backgroundLink:", this.htmlData.backgroundLink);
      }
    },

    async uploadFile(file) {
      try {
        var formData = new FormData();
        formData.append("file", file);
        formData.append("type", "gopagesImage");
        formData.append("companyId", this.$store.state.companyDetails.id);
        var config = {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: this.$store.state.user.token,
          },
        };

        //store the file to storage
        var attachment = await this.$http.post(
          `${this.$store.state.ENDPOINT}/attachments/upload`,
          formData,
          config
        );
        attachment = attachment.body;
        return attachment;
      } catch (e) {
        this.loader = false;
      }
    },
    selectColor(color) {
      this.htmlData.themeColor = color;
    },
    openAddList() {
      this.editedIndex = -1;
      this.listItem = {
        name: "",
        teamsId: "",
        desc: "",
      };

      this.isDialogHandling();
    },
    isDialogHandling() {
      console.log("before value", this.isDialogOpen);
      this.isDialogOpen = !this.isDialogOpen;
      console.log("after value", this.isDialogOpen);
    },
    getId(id) {
      console.log("this.activeId", id);
      this.activeId = id;
      this.getValue(id);
      this.dialog = true;
    },
    getValue(input) {
      switch (input) {
        case "Company Name":
          this.activeValue = this.htmlData.title;
          break;
        case "Description":
          this.activeValue = this.htmlData.subHeading;
          break;
        // case "Items":
        //   this.activeValue = this.htmlData.highlightItems;
        //   break;
        default:
          break;
      }
    },
    setInputValue(input, value) {
      this.setValue(input, value);
      this.dialog = false;
    },
    getOptions() {
      this.isForm = true;
      this.dialog = true;
      this.activeId = "Form";
    },
    handleClose() {
      this.isForm = false;
      this.isList = false;
      this.dialog = false;
    },
    saveForm() {
      this.isForm = false;
      this.dialog = false;
    },
    saveList() {
      this.isList = false;
      this.dialog = false;
    },
    getList() {
      this.isList = true;
      this.dialog = true;
      this.activeId = "List";
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];

        var teamIds = this.getTeamIds();

        console.log("teamIds", teamIds);

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;

        this.listId = this.lists[0].id;

        console.log("this.lists", this.lists);

        if (this.lists.length < 1) {
          this.loader = false;
          return;
        }
      } catch (e) {
        this.loader = false;
        console.log("error in list fetch", e);
      }
    },
    async saveLeadForm() {
      // a payload is initialised
      var payload = {};
      // payload.title = this.htmlData.title; // form Name
      // payload.subHeading = this.htmlData.subHeading; // form Desc
      payload.createdOn = new Date().toISOString(); //form CreatedOn date
      // payload.highlightItems = this.htmlData.highlightItems;
      // payload.submitButtonText = this.htmlData.submitButtonText;
      payload.content = [
        {
          title: this.htmlData.title,
          subHeading: this.htmlData.subHeading,
          highlightItems: this.htmlData.highlightItems,
        },
      ];
      payload.formField = [
        {
          nameEnabled: this.htmlData.nameEnabled,
          emailEnabled: this.htmlData.emailEnabled,
          phoneEnabled: this.htmlData.phoneEnabled,
          addressEnabled: this.htmlData.addressEnabled,
          companyNameEnabled: this.htmlData.companyNameEnabled,
          submitButtonText: this.htmlData.submitButtonText,
          termsAndConditionsUrl: this.htmlData.termsAndConditionsUrl,
          privacyPolicyUrl: this.htmlData.privacyPolicyUrl,
          nameInputText: this.htmlData.nameInputText,
          emailInputText: this.htmlData.emailInputText,
          contactInputText: this.htmlData.contactInputText,
          addressInputText: this.htmlData.addressInputText,
          companyInputText: this.htmlData.companyInputText,
          formTitle: this.htmlData.formTitle,
          declarationTextOne: this.htmlData.declarationTextOne,
          declarationTextTwo: this.htmlData.declarationTextTwo,
          declarationTextThree: this.htmlData.declarationTextThree,
          declarationTextFour: this.htmlData.declarationTextFour,
        },
      ];
      // payload.nameEnabled = this.htmlData.nameEnabled;
      // payload.emailEnabled = this.htmlData.emailEnabled;
      // payload.phoneEnabled = this.htmlData.phoneEnabled;
      // payload.addressEnabled = this.htmlData.addressEnabled;
      // payload.companyNameEnabled = this.htmlData.companyNameEnabled;
      payload.companyId = this.$store.state.user.companyId; // company ID
      payload.accountsId = this.$store.state.user.id; //account ID
      payload.listsId = this.listId; //list Ids where the form belongs

      if (typeof this.formId == "undefined") {
        payload.name = this.itemNameandColor.name;
        payload.themeColor = this.itemNameandColor.themeColor;
      } else {
        payload.themeColor = this.htmlData.themeColor;
      }

      payload.themeId = this.themeId;
      payload.logoLink = this.htmlData.logoLink;
      payload.backgroundLink = this.htmlData.backgroundLink;
      const self = this;
      await self.$http
        .get(
          `${this.ENDPOINT}/externals/token?access_token=${this.$store.state.user.token}`
        )
        .then((response) => {
          console.log("response.body.token", response.body.id);
          payload.token = response.body.id;
        })
        .catch((err) => {
          self.$swal({ type: "error", text: err.body.error.message });
        });

      console.log("company details", this.$store.state.user);

      console.log("payload for updaTE", payload);

      if (!this.formId || this.formId == "") {
        // storing new form
        this.$http
          .post(`${this.ENDPOINT}/integrateForms`, payload) //storing the form in the database
          .then((res) => {
            // console.log("Form Response: ",res )
            this.$swal({
              type: "success",
              text: "New Form is created",
            }).then((yes) => {
              // after storing the message is displayed
              if (yes.value) {
                // on user click redirected to forms page
                this.$router.go(-1);
              }
            });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          }); // in case of error in storing the form
      } else {
        // updating old form
        this.$http
          .patch(
            `${this.$store.state.ENDPOINT}/integrateForms/${this.formId}`,
            payload
          ) //updating the form in the database
          .then((res) => {
            // console.log("Form Response: ",res )
            this.$swal({
              type: "success",
              text: "Updated",
            }).then((yes) => {
              // after updating the message is displayed
              if (yes.value) {
                // on user click redirected to forms page
                this.$router.go(-1);
              }
            });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          }); // in case of error in storing the form
      }
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.button-card {
  /* height: 20%; */
  padding: 3rem 3rem 0;
}

.content-logo {
  width: 5rem;
  height: auto;
  margin: auto;
}

.image-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-btn-text {
  color: #00cf46;
  font-size: 0.8rem;
}

.logo-container {
  width: 100%;
  max-width: 10rem;
  padding-inline: 1rem;
}

.logo-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.input-checkbox {
  margin: 0;
  padding-top: 1px;
}

.declaration {
  margin: 0;
  line-height: 1.4rem;
}

.full-width {
  width: 100%;
}

#form-header {
  margin-bottom: 0.8rem;
}

.margin-top {
  margin-top: 2rem;
}

.badge {
  display: block;
}

/* .margin-right {
  margin-right: 2rem;
} */

.text-container {
  margin-top: 2rem;
}

.list-container {
  background: none;
}

.content-title {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 800;
}

.content-desc {
  margin-top: 1rem;
  font-size: 1rem;
}

.html-container {
  /* Add your custom container styles here */
  width: 100%;
  height: auto;
  font-family: "Poppins", sans-serif;

  /* background: #fafafa;
  font-family: "Poppins", sans-serif; */
}

.main-container {
  width: 100%;
  /* background: #fafafa; */
  background: white;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  border: 1px solid #c8d5e2;
  border-radius: 12px;
  overflow: hidden;
}

.main-container ul {
  padding-left: 0;
}

.left-card {
  background: transparent;
  background-color: none;
  box-shadow: none !important;
  /* height: 70%; */
  padding: 2rem 3rem;
  width: 100%;
}

.leftCol {
  width: 50%;
  height: 100%;
  /* padding: 3.25rem; */
}

.rightCol {
  width: 50%;
  padding: 3.25rem;
}

.display-none {
  display: none;
}

.mainForm {
  background-color: white;
  padding: 1.875rem;
  border-radius: 0.625rem;
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

.mainForm form label,
form input,
form textarea {
  display: block;
  margin-block: 0.8rem;

  width: 100%;
}

.mainForm input {
  height: 2.5rem;
  border-radius: 5px;
  border: 1px solid black;
}

.buttonForm {
  /* background-color: #37cf37; */
  text-transform: capitalize;
  color: #fafafa;
  border: none;
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

@media only screen and (max-width: 40em) {
  .main-container {
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .rightCol {
    width: 100%;
    padding: 1.5rem;
  }

  .leftCol {
    width: 100%;
    padding: 1.5rem;
    height: auto;
  }

  .button-card {
    height: auto;
  }
}

@media only screen and (min-width: 40em) and (max-width: 64em) {
  .main-container {
    margin: 0 auto;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .rightCol {
    width: 100%;
    padding: 3rem;
  }

  .leftCol {
    width: 100%;
    padding: 1rem;
    height: auto;
  }

  .button-card {
    height: auto;
    padding: 1rem;
  }
}

@media (min-width: 1200px) {
  .customPaddingForColor {
    margin-top: -36px !important;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
  .customPaddingForColor {
    margin-top: -36px !important;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .customPaddingForColor {
    margin-top: -12px !important;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .customPaddingForColor {
    margin-top: -12px !important;
  }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
  .customPaddingForColor {
    margin-top: -12px !important;
  }
}
</style>
