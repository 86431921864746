<template>
  <div class="dispo">
    <v-combobox
      v-model="selectedDispo"
      class="pt-1 customBorderRadius"
      :items="filterOptionsDispo"
      item-text="name"
      item-value="id"
      label="All Dispositions"
      prepend-inner-icon="filter_list"
      autocomplete
      allow-overflow
      hide-details
      multiple
      clearable
      chips
      dense
      filled
      solo-inverted
      flat
      small-chips
      @change="filterByDispo"
    >
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip v-bind="attrs" :input-value="selected" @click="select">
          <strong>{{ item.name }}</strong
          >&nbsp;
        </v-chip>
      </template>

      <template v-slot:append-item>
        <v-list-item link to="/dispo">
          <v-list-item-title style="color: green"
            ><v-icon color="green" style="margin-bottom: 5px;"
              >add_circle_outline</v-icon
            ><span style="padding-top:20px"
              >Add Disposition</span
            ></v-list-item-title
          >
        </v-list-item>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {},
  data() {
    return { selectedDispo: [] };
  },
  computed: {
    ...mapGetters(["dispoList"]),
    filterOptionsDispo() {
      var dispo = _.filter(this.dispoList, (d) => {
        return (
          d.title != "SMS" &&
          d.title != "EMAIL" &&
          d.title != "LINK" &&
          d.title != "APP" &&
          d.title != "SHARE"
        );
      });
      return [
        ..._.map(dispo, (d) => {
          return {
            type: "dispo",
            name: d.title,
            id: d.title,
          };
        }),
      ];
    },
  },
  methods: {
    filterByDispo() {
      var dispo = this.selectedDispo.map((d) => d.id);

      console.log("dis from component", dispo);
      this.$emit("filterByDispo", dispo);
    },

    goToAddDisposition() {
      this.$router.push("/dispo");
    },
  },
};
</script>

<style>
.dispo .v-select__selections {
  height: 29px !important;
  overflow-y: auto;
}
</style>
