<template>
  <div>
    <v-dialog persistent width="450" v-model="callScriptDialog">
      <v-card>
        <v-card-title class="primarygrad">Call Script</v-card-title>
        <v-card-text style="padding: 15px 25px">
          <v-row style="margin: 0px">
            <v-row style="margin: 0px 0px 15px 0px">
              <v-flex xs12 sm8 md8>
                <v-select
                  prepend-icon="input"
                  outlined
                  dense
                  v-model="selectedField"
                  :items="fields"
                ></v-select>
              </v-flex>
              <v-flex
                xs12
                sm4
                md4
                style="text-align: center !important; margin-top: 2px; padding: 0px 20px;"
              >
                <v-btn
                  block
                  depressed
                  color="primarygrad"
                  style="text-transform: none; letter-spacing: 0px"
                  @click="customValue"
                  >Add to Text</v-btn
                >
              </v-flex>
            </v-row>
            <v-row style="margin: 0px 0px 15px 0px">
              <v-textarea
                placeholder="Enter the text, feel free to add variables from the above box."
                ref="templateArea"
                id="templateArea"
                prepend-icon="text_format"
                v-model="script"
                outlined
                messages="Enter the text, feel free to add variables from the above
                    box."
                rows="12"
              ></v-textarea>
            </v-row>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="callScriptDialog = false"
            >Cancel</v-btn
          >
          <v-btn depressed color="primarygrad" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    lists: {
      type: Array,
    },
    selectedList: {
      type: String,
    },
  },
  data() {
    return {
      callScriptDialog: false,
      selectedField: "Name",
      fields: [
        "Agent's Name",
        "Agent's Phone",
        "Agent's Email",
        "Name",
        "Phone",
        "Email",
        "Company",
        "Remarks",
        "Note",
      ],
      selectedListObj: {},
      script: "",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["ENDPOINT", "fieldList"]),
  },
  methods: {
    getAllCustomFields() {
      this.allCustomFields = [...this.fieldList];
      for (var i = 0; i < this.allCustomFields.length; i++) {
        // Datatable Header
        this.fields.push(this.allCustomFields[i].name);
      }
    },
    async open() {
      const self = this;
      await self.getAllCustomFields();
      await self.getList();
      self.callScriptDialog = true;
    },
    getList() {
      const self = this;
      self.selectedListObj = _.find(self.lists, (l) => {
        return l.id == self.selectedList;
      });
      self.script = self.selectedListObj.callScript;
    },
    async save() {
      const self = this;
      try {
        var listObj = {
          callScript: self.script,
        };
        await self.$http.patch(
          `${self.ENDPOINT}/lists/${self.selectedList}`,
          listObj
        );
        self.callScriptDialog = false;
        self.$emit("refreshData");
      } catch (e) {
        self.callScriptDialog = false;
        self.$swal({
          type: "warning",
          text: "Error occured. Please try again.",
        });
        console.log("Error: Update Callscript", e);
      }
    },
    customValue() {
      var data = "";
      // Agents information
      if (this.selectedField == "Agent's Name") {
        data = "${agentName}";
      }
      if (this.selectedField == "Agent's Phone") {
        data = "${agentPhone}";
      }
      if (this.selectedField == "Agent's Email") {
        data = "${agentEmail}";
      }

      if (this.selectedField == "Phone") {
        data = "${phone}";
      }
      if (this.selectedField == "Name") {
        data = "${name}";
      }
      if (this.selectedField == "Email") {
        data = "${email}";
      }
      if (this.selectedField == "Remarks") {
        data = "${remarks}";
      }
      if (this.selectedField == "Company") {
        data = "${companyName}";
      }
      if (this.selectedField == "Note") {
        data = "${note}";
      }

      // Check & split
      var singleField = _.find(this.allCustomFields, (field) => {
        return field.name.toLowerCase() == this.selectedField.toLowerCase();
      });
      if (singleField) {
        data = "${" + `${singleField.name.toLowerCase()}` + "}";
      }

      this.insertAtCaret(data);
    },
    insertAtCaret(text) {
      var txtarea = this.$refs.templateArea.$refs.input;
      var scrollPos = txtarea.scrollTop;
      var strPos = 0;
      var br =
        txtarea.selectionStart || txtarea.selectionStart == "0"
          ? "ff"
          : document.selection
          ? "ie"
          : false;

      if (br == "ie") {
        txtarea.focus();
        var range = document.selection.createRange();
        range.moveStart("character", -txtarea.value.length);
        strPos = range.text.length;
      } else if (br == "ff") strPos = txtarea.selectionStart;
      var front = txtarea.value.substring(0, strPos);
      var back = txtarea.value.substring(strPos, txtarea.value.length);
      txtarea.value = front + text + back;

      this.script = front + text + back;

      strPos = strPos + text.length;
      if (br == "ie") {
        txtarea.focus();
        range = document.selection.createRange();
        range.moveStart("character", -txtarea.value.length);
        range.moveStart("character", strPos);
        range.moveEnd("character", 0);
        range.select();
      } else if (br == "ff") {
        txtarea.selectionStart = strPos;
        txtarea.selectionEnd = strPos;
        txtarea.focus();
      }
      txtarea.scrollTop = scrollPos;
    },
  },
};
</script>

<style></style>
