import moment from 'moment-timezone';
const _ = require('lodash');
let searchFilter = {};

searchFilter.getFilter = function (selectField, searchValue, selectOption, altSearchValue) {
	return new Promise(async (resolve, reject) => {
		let lF = {};
		// console.log('From New Js--->', selectField, searchValue, selectOption, altSearchValue);
		// Filter Var Config
		lF.field = selectField;
		lF.value = searchValue;
		lF.option = selectOption;
		if (selectField.type == 'mcq') {
			lF.field.type = 'text';
		}
		if (selectField.type == 'text' && selectOption.value == 'blank') {
			lF.value = "";
		} else if (selectField.type == 'checkbox') {
			lF.value = searchValue;
		} else if ((selectField.type == 'number' || selectField.type == 'date') && selectOption.value == 'between') {
			lF.altSearchValue = altSearchValue;
		}

		if (lF.field.type == 'text') {
			lF.value = lF.value.trim();
		}

		resolve(lF);
	});
};

searchFilter.getFilterPayload = function (filters, dispos, operator, sortField, sortOrder) {
	return new Promise(async (resolve, reject) => {
		let predicates = [];
		let selectedDispo = [];
		let selectedOperator = '';
		let selectedSortOrder = 'asc';

		if (
			dispos &&
			!_.find(dispos, d => {
				return d.title == 'All';
			})
		) {
			selectedDispo = dispos;
		}
		// Filters - Predicate Set
		if (filters && filters.length > 0) {
			// Predicates Cofig Using Filters
			filters.forEach(f => {
				let pdt = {};
				pdt.attribute = f.field.fieldType == 'customField' ? 'customFields.' + f.field.value : f.field.value;
				pdt.type = f.field.type == 'text' ? 'string' : f.field.type;
				pdt.operator = f.option.value;
				pdt.value = f.value;
				// Number Type Send Array
				if (f.field.type == 'number' && typeof f.altSearchValue != 'undefined') {
					// Between Logic
					pdt.value = [Number(f.value), Number(f.altSearchValue)];
				} else if (f.field.type == 'number' && typeof f.altSearchValue == 'undefined') {
					// Gte & Lte Logic
					pdt.value = [f.value];
				} else if (f.field.type == 'date' && typeof f.altSearchValue != 'undefined') {
					// Between Logic
					pdt.value = [moment(f.value, "DD-MM-YY").format(), moment(f.altSearchValue, "DD-MM-YY").format()];
				} else if (f.field.type == 'date' && typeof f.altSearchValue == 'undefined') {
					// Gte & Lte Logic
					pdt.value = [moment(f.value, "DD-MM-YY").format()];
				}
				predicates.push(pdt);
			});
		}

		// Operator Set for More Filters
		if (filters && filters.length > 1) {
			selectedOperator = operator;
		}

	 
		let selectedSortField = ""
		// Check CustomField & Set SortField
		if(typeof sortField == "string"){
			selectedSortField = sortField
			 
		}else{
			if (!sortField) {
				sortField = {
					name: "Phone",
					value: "phone",
					fieldType: "normalField",
					type: "text",
				};
			}
			  selectedSortField = sortField.value;
			  if (sortField && sortField.fieldType == 'customField') {
				selectedSortField = 'customFields.' + sortField.value;
			}

		}
		
	
		
		
		if(typeof sortOrder == "string") {
			selectedSortOrder = sortOrder
		}else if (sortOrder) {
			selectedSortOrder = sortOrder.id;
		}
 

		resolve({
			dispos: selectedDispo,
			sortField: selectedSortField,
			sortOrder: selectedSortOrder,
			predicates: predicates,
			matchType: selectedOperator
		});
	});
};

export default searchFilter;